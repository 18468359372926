<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w ">
      <ShopHeader />
      <div class="box2">
        <div class="box2_hd">
          <h3>商品搜索</h3>
        </div>
        <hr style="border: 1px solid #00a4ff;" />
        <div class="box2_bd" v-if="totalNum !== 0">
          <ul class="clearfix">
            <li v-for="goods in goodsDate" :key="goods.spbh">
              <img v-if="!goods.img" :src="defaultImg" />
              <img v-if="goods.img" :src="goods.img1" />
              <h4>{{ goods.spmc }}</h4>
              <h4>{{ goods.spgg }}</h4>
              <div class="goodsSearch_info">
                <span>¥{{ goods.spcbdj }}</span>
                <el-input-number
                  size="mini"
                  v-model="goods.carNum"
                  style="margin-left:15px;width:100px;"
                  :min="1"
                ></el-input-number>
                <el-button
                  style="float: right; font-size: 16px;position: relative;top:-8px;"
                  type="text"
                  @click="addShoppingCar(goods.spbh, goods.carNum)"
                  >预订</el-button
                >
                <!-- <a href="#" @click.prevent="addShoppingCar(goods.spbh)"
                  >加入我的预订</a
                > -->
              </div>
            </li>
          </ul>
        </div>
        <div class="goodsfengye" v-if="totalNum !== 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalNum"
            :page-size="query.pageSize"
            :current-page="query.pageNum"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <div class="noGoodsSearch" v-if="totalNum === 0">
          您所搜索的商品可能客户经理还未和您确认状态和价格,请联系客户经理.
        </div>
      </div>

      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import { mapMutations } from "vuex";
import ShopNavigation from "../components/ShopNavigation.vue";

export default {
  name: "GoodSearch",
  components: {
    ShopHeader,
    XsjlInfo,
    ShopNavigation
  },
  data() {
    return {
      query: {
        keyWorld: "",
        xiLeiName: "",
        pageNum: 1,
        pageSize: 30
      },
      goodsDate: [],
      totalNum: 0,
      searchType: this.$route.query.searchType,
      carNum: 1,
      defaultImg: require("../assets/img/goods01.jpg")
    };
  },
  mounted() {
    this.searchGoods();
  },
  methods: {
    ...mapMutations(["updateShoppingCarNum"]),
    async searchGoods() {
      // if (this.searchType === 0) {
      //   this.query.xiLeiName = this.$route.query.keyWorld;
      // }
      // if (this.searchType === 1) {
      //   this.query.keyWorld = this.$route.query.keyWorld;
      // }
      this.query.xiLeiName = this.$route.query.xiLeiName;
      this.query.keyWorld = this.$route.query.keyWorld;
      const { data: res } = await this.$http.post(
        "/goods/goodsSearch",
        this.query
      );
      if (res.code === "K-000000") {
        this.goodsDate = res.context.records;
        this.goodsDate.forEach(item => {
          if (item.img && item.img.length > 5) {
            item.img1 = "http://jrsoft.jswcsrq.com:81" + item.img;
          }
        });
        this.totalNum = res.context.total;
      }
    },
    async searchGoodsKeyWorld(keyWorld, pageNum, xiLeiName) {
      console.log(keyWorld, pageNum, xiLeiName);
      this.query.keyWorld = keyWorld;
      this.query.xiLeiName = xiLeiName;
      this.query.pageNum = pageNum;
      const { data: res } = await this.$http.post(
        "/goods/goodsSearch",
        this.query
      );
      if (res.code === "K-000000") {
        this.goodsDate = res.context.records;
        this.totalNum = res.context.total;
        this.goodsDate.forEach(item => {
          if (item.img && item.img.length > 5) {
            item.img1 = "http://jrsoft.jswcsrq.com:81" + item.img;
          }
        });
      }
    },
    handleCurrentChange(newPageNum) {
      this.query.pageNum = newPageNum;
      if (this.searchType !== 1) {
        this.searchGoods();
      } else {
        this.searchGoodsKeyWorld(
          this.query.keyWorld,
          this.query.pageNum,
          this.query.xiLeiName
        );
      }
    },
    async addShoppingCar(spbh, carNum) {
      const { data: res } = await this.$http.post("/shoppingcar/add", {
        spbh: spbh,
        spNum: carNum,
        delFlag: 1
      });
      if (res.code === "K-000000") {
        this.updateShoppingCarNum(carNum);
        this.$message.success("成功加入我的预订商品");
      } else {
        this.$message.error("加入我的预订商品失败");
      }
    }
  }
};
</script>

<style scoped>
.goodsfengye {
  text-align: center;
  margin-top: 20px;
}
.noGoodsSearch {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.box2_bd .goodsSearch_info {
  margin: 20px 10px;
}

.box2_bd .goodsSearch_info span {
  color: #cb4255;
  font-size: 13px;
  font-weight: 700;
}
</style>
