<template>
  <div class="login_main allLoading">
    <div class="login_header">
      <img data-v-29f959ab="" :src="company1" />
      <h1>维创散热器云端互动</h1>
    </div>

    <div class="login_body">
      <div class="gsimg">
        <img :src="company2" />
      </div>
      <div class="login">
        <h3>修改密码</h3>
        <div class="username">
          <span>账号:</span><input type="text" v-model="user.userName" />
        </div>
        <div class="password" style="margin-left:0.2vw;">
          <span>新密码:</span><input type="password" v-model="user.userPsw" />
        </div>
        <div class="password">
          <span>密码:</span><input type="password" v-model="user.userPsw2" />
        </div>
        <a href="#" @click.prevent="updatepwd">确认</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordUpdate",
  data() {
    return {
      user: {
        userName: "",
        userPsw: "",
        userPsw2: ""
      },
      company1: require("../assets/img/logo_new.png"),
      company2: require("../assets/img/company2.png")
    };
  },
  methods: {
    async updatepwd() {
      if (!this.user.userName) {
        return this.$message.error("请输入账号");
      }
      if (!this.user.userPsw) {
        return this.$message.error("请输入新密码");
      }
      if (!this.user.userPsw2) {
        return this.$message.error("请输入密码");
      }
      if (this.user.userPsw2 !== this.user.userPsw) {
        return this.$message.error("两次输入密码不一致");
      }

      const { data: res } = await this.$http.post("/user/updatepsw", this.user);

      if (res.code === "K-000007") {
        return this.$message.error("非法参数");
      }
      if (res.code === "K-000009") {
        return this.$message.error("修改的账号与当前账号不一致");
      }
      if (res.code === "K-000000") {
        this.$router.push({
          name: "Shop"
        });
      }
    }
  }
};
</script>

<style scoped>
.login_main {
  background-image: url(../assets/img/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.login_header {
  height: 18.2vh;
  width: 65vw;
  margin: 0 auto;
  cursor: pointer;
}

.login_header > img {
  float: left;
  width: 14.4vw;
  height: 10vh;
  margin-top: 4.6vh;
}

.login_header > h1 {
  float: right;
  display: inline;
  color: white;
  margin-top: 5.5vh;
  margin-right: 11.7vw;
  font-size: 3vw;
}

.login_body {
  width: 65vw;
  height: 36.5vh;
  margin: 0 auto;
  margin-top: 2vh;
}

.gsimg {
  float: left;
  width: 40.5vw;
  height: 42.4vh;
}

.gsimg > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.login {
  float: left;
  background-color: rgb(1, 3, 14);
  width: 21.7vw;
  height: 42.4vh;
  margin-left: 2.7vw;
  opacity: 0.5;
  border-radius: 10px;
}

.login > h3 {
  text-align: center;
  color: white;
  margin-top: 3vh;
  font-size: 2vw;
}

.username,
.password {
  width: 100%;
  margin: 0 auto;
  margin-top: 2.4vh;
  margin-left: 1.4vw;
  color: white;
  font-size: 1.2vw;
}

.username > input {
  width: 16vw;
  height: 3.3vh;
  font-size: 1vw;
}

.password > input {
  width: 16vw;
  height: 3.3vh;
  font-size: 1vw;
}

.login > a {
  display: block;
  border: 1px solid #00a4ff;
  width: 18.5vw;
  height: 3.5vh;
  text-align: center;
  line-height: 3.2vh;
  color: white;
  background-color: #00a4ff;
  margin: 1.8vw auto;
  font-size: 1.5vw;
  font-weight: 700;
  border-radius: 5px;
}

.wxdiv {
  text-align: center;
  color: white;
  height: 1.9vh;
  line-height: 1.9vh;
  cursor: pointer;
}

.wxiframe {
  width: 300px;
  height: 400px;
  margin: 10px 50px;
}
</style>
