<template>
  <div class="  mainPage">
    <div class="mainPage_header">
      <img :src="company1" />
      <h1>维创散热器云端互动</h1>
    </div>
    <Lunbo />
    <MainBody />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Lunbo from "@/components/Lunbo";
import MainBody from "@/components/MainBody";
import Footer from "@/components/Footer";

export default {
  name: "Home",
  components: {
    Lunbo,
    MainBody
    // Footer
  },
  data() {
    return {
      company1: require("../assets/img/company_logo03.png")
    };
  }
};
</script>
<style scoped>
.mainPage {
  background-image: url(../assets/img/bg.jpg);
  background-position: center;
  background-repeat: repeat-y;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
.mainPage_header {
  height: 11vh;
  width: 65vw;
  margin: 0 auto;
  cursor: pointer;
}

.mainPage_header > img {
  width: 6.5vw;
  height: 10vh;
  float: left;
}

.mainPage_header > h1 {
  float: left;
  display: inline;
  color: #fff;
  margin-top: 0.7vw;
  margin-left: 14vw;
  font-size: 2.7vw;
}
</style>
