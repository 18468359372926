<template>
  <div class="w">
    <swiper :options="swiperOption">
      <swiper-slide class="swiper-slide"> <img :src="company6"/></swiper-slide>
      <swiper-slide class="swiper-slide"> <img :src="company7"/></swiper-slide>
      <swiper-slide class="swiper-slide"> <img :src="company5"/></swiper-slide>
      <swiper-slide class="swiper-slide"> <img :src="company3"/></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "Lunbo",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: ".swiper-pagination", //分页器作用对象
          clickable: true //分页器小圆点是否可点击
        },
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        }
      },
      company8: require("../assets/img/company8.png"),
      company5: require("../assets/img/company5.jpg"),
      company6: require("../assets/img/company61.jpg"),
      company7: require("../assets/img/company7.jpg"),
      company3: require("../assets/img/company32.jpg")
    };
  }
};
</script>

<style scope>
.swiper-slide {
  height: 38.5vh;
  font-size: 5.5vh;
  text-align: center;
  line-height: 41.8vh;
}

.swiper-slide img {
  width: 100%;
  height: 38.5vh;
}

.swiper-container {
  border-radius: 10px;
}
</style>
