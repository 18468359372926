<template>
  <div class="w">
    <Lunbo />
    <MainBody2 />
    <Footer />
  </div>
</template>

<script>
import Lunbo from "@/components/Lunbo";
import Footer from "@/components/Footer";
import MainBody2 from "@/components/MainBody2";
export default {
  name: "Jszc",
  components: {
    Lunbo,
    Footer,
    MainBody2
  },
  methods: {}
};
</script>
