<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w">
      <ShopHeader />
    </div>
    <div style="width:1200px;margin:0 auto;">
      <div class="orderCentertitle">
        <h3>订单中心</h3>
      </div>
      <div class="orderCenter">
        <hr style="border: 1px solid #00a4ff;" />
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="全部" name="all">
            <el-select
              v-model="queryOrder.orderStatus"
              placeholder="请选择订单状态"
              class="myorderselect"
            >
              <el-option
                v-for="item in orderStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>

            <OrderData :tableData="tableData2" />
          </el-tab-pane>
          <el-tab-pane label="待受理" name="one">
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData2" />
          </el-tab-pane>
          <el-tab-pane label="待生产" name="second">
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData2"
          /></el-tab-pane>
          <el-tab-pane label="待发货" name="third">
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData2"
          /></el-tab-pane>
          <el-tab-pane label="已发货" name="fourth">
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData2"
          /></el-tab-pane>

          <el-tab-pane label="已作废" name="fifth">
            <el-date-picker
              v-model="dateAry"
              type="daterange"
              align="center"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              style="margin-right:10px;"
            >
            </el-date-picker>

            <el-input
              v-model="queryOrder.spgg"
              placeholder="请输入商品型号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData2"
          /></el-tab-pane>

          <!-- <el-tab-pane label="待付款" name="first">
            <el-input
              v-model="queryOrder.orderCode"
              placeholder="请输入订单编号"
              style="width:300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="queryOder"
              ></el-button>
            </el-input>
            <OrderData :tableData="tableData"
          /></el-tab-pane> -->
        </el-tabs>

        <div class="myorderfengye">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalNum"
            :page-size="queryOrder.pageSize"
            :current-page="queryOrder.pageNum"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import OrderData from "../components/OrderData.vue";
import ShopNavigation from "../components/ShopNavigation.vue";
export default {
  name: "myOrder",
  components: {
    ShopHeader,
    XsjlInfo,
    OrderData,
    ShopNavigation
  },
  data() {
    return {
      activeName: "all",
      orderStatus: [
        {
          value: "",
          label: "全部"
        },
        {
          value: 1,
          label: "待受理"
        },
        {
          value: 2,
          label: "待生产"
        },
        {
          value: 3,
          label: "待发货"
        },
        {
          value: 4,
          label: "已发货"
        },
        {
          value: 5,
          label: "已作废"
        }
      ],
      queryOrder: {
        orderStatus: "",
        pageNum: 1,
        pageSize: 10,
        spgg: "",
        startTime: "",
        endTime: ""
      },
      tableData: [],
      tableData2: [],
      totalNum: 0,
      dateAry: [],
      pickerOptions: {
        disabledDate: time => {
          let nowDate = new Date();
          let oneDay = 1000 * 60 * 60 * 24;
          let oneYearLater = new Date(nowDate.getTime() - oneDay * 180);
          return time.getTime() > nowDate || time.getTime() < oneYearLater; //注意是||不是&&
        }
      }
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    async getOrderList() {
      console.log(this.dateAry);
      if (this.dateAry && this.dateAry.length === 2) {
        this.queryOrder.startTime = this.dateAry[0] + " 00:00:00";
        this.queryOrder.endTime = this.dateAry[1] + " 23:59:59";
      } else {
        this.queryOrder.startTime = "";
        this.queryOrder.endTime = "";
      }
      const { data: res } = await this.$http.post(
        "/order/orderList",
        this.queryOrder
      );
      console.log(res);
      if (res.code === "K-000000") {
        this.tableData2 = res.context.records;
        this.totalNum = res.context.total;
        // this.tableData2 = [];
        // if (this.tableData.length !== 0) {
        //   this.tableData.forEach(item => {
        //     item.customerOrderGoodsVOList.forEach((car, index) => {
        //       let newItem = { ...item };
        //       newItem.spGg = car.spGg;
        //       newItem.spNum = car.spNum;
        //       newItem.remarks = car.remarks;
        //       newItem.spMoney = car.spMoney;
        //       newItem.deliverGoodsDate1 = car.deliverGoodsDate;
        //       newItem.deliverGoodsNum1 = car.deliverGoodsNum;
        //       newItem.orderGoodsStatus1 = car.orderGoodsStatus;
        //       if (index === 0) {
        //         newItem.lasted = "1";
        //       } else {
        //         newItem.lasted = "0";
        //       }
        //       this.tableData2.push(newItem);
        //     });
        //   });
        // }
        //console.log(this.tableData2);
      } else {
        this.$message.error("获取订单信息失败");
      }
    },
    handleCurrentChange(newPageNum) {
      this.queryOrder.pageNum = newPageNum;
      this.getOrderList();
    },
    handleTabClick(tab, event) {
      this.queryOrder = {
        orderStatus: "",
        pageNum: 1,
        pageSize: 10,
        spgg: "",
        startTime: "",
        endTime: ""
      };
      this.dateAry = [];
      if (this.activeName === "all") {
        this.queryOrder.orderStatus = "";
      }
      if (this.activeName === "one") {
        this.queryOrder.orderStatus = 1;
      }
      if (this.activeName === "second") {
        this.queryOrder.orderStatus = 2;
      }
      if (this.activeName === "third") {
        this.queryOrder.orderStatus = 3;
      }
      if (this.activeName === "fourth") {
        this.queryOrder.orderStatus = 4;
      }
      if (this.activeName === "fifth") {
        this.queryOrder.orderStatus = 5;
      }
      this.getOrderList();
    },
    queryOder() {
      this.queryOrder.pageNum = 1;
      this.getOrderList();
    }
  }
};
</script>

<style scoped>
.orderCentertitle {
  height: 45px;
  line-height: 45px;
}

.orderCentertitle > h3 {
  font-size: 20px;
  color: #494949;
  width: 150px;
}

.orderCenter {
  margin-top: 10px;
  color: #565353;
  font-size: 16px;
}
.myorderselect {
  margin-right: 10px;
}

.myorderfengye {
  text-align: center;
  margin-top: 20px;
}
</style>
