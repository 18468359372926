import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './assets/css/global.css'
import './plugins/element.js'
import store from './store'
import { Loading } from "element-ui";

Vue.config.productionTip = false


let loadingRequestCount = 0;
let loadingInstance;
const showLoading = () => {
  if (loadingRequestCount === 0) {
      loadingInstance = Loading.service({ target: '.allLoading',  
         fullscreen: true,
        text: "数据提交中", 
        background: "rgba(0, 0, 0, 0.7)" });
  }
  loadingRequestCount++
}




//配置请求的根路径
//axios.defaults.baseURL = "/shop"
axios.defaults.baseURL = "http://client.jswcsrq.com/shop"

// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  return config
})

axios.interceptors.request.use(config => {
  let token = window.sessionStorage.getItem('token')
  if(token){
    config.headers = {
      'token': token
     }
  }
  showLoading(config)
  return config
})

// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  setTimeout(() => {
      hideLoading()
  }, 200);

  if(config.data.code==='K-000006'){
    router.push({
      name: "Login"
    });
  }
  return config
})


Vue.prototype.$http = axios;

const vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


const hideLoading = () => {
  if (loadingRequestCount <= 0) return
  loadingRequestCount--
  if (loadingRequestCount === 0) {
      console.log(loadingRequestCount,"loadingRequestCount")
      vue.$nextTick(()=>{//以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close();
      });
  }
}