<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w">
      <ShopHeader />
      <div class="orderSucces">
        <hr style="border: 1px solid #00a4ff;" />
        <img :src="successImg" />

        <div>
          订单提交成功！
          您的订单已提交审核，您可在个人中心-我的订单查看处理进度。
        </div>

        <!-- <div class="orderSucessInfo">
          <div class="orderSucessInfoLeft">
            订单编号:
          </div>
          <div class="orderSucessInfoRight">
            {{ orderCode }}
          </div>
        </div> -->

        <div class="orderSucessInfo">
          <div class="orderSucessInfoLeft">
            订单金额:
          </div>
          <div class="orderSucessInfoRight">￥{{ money }}</div>
        </div>

        <div class="orderSucessInfo">
          <div class="orderSucessInfoLeft"></div>
          <div
            class="orderSucessInfoRight"
            @click="goOrderCenter"
            style=" cursor: pointer;"
          >
            查看详情
          </div>
        </div>
      </div>
      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import ShopNavigation from "../components/ShopNavigation.vue";
export default {
  name: "OrderSuccess",
  components: {
    ShopHeader,
    ShopNavigation,
    XsjlInfo
  },
  data() {
    return {
      successImg: require("../assets/img/success.png"),
      orderCode: this.$route.query.orderCode,
      money: this.$route.query.money
    };
  },
  methods: {
    goOrderCenter() {
      this.$router.push({
        name: "MyOrder"
      });
    }
  }
};
</script>

<style scoped>
.orderSucces {
  margin-top: 20px;
  height: 400px;
  text-align: center;
  font-size: 16px;
  color: #00a4ff;
  margin-bottom: 100px;
}

.orderSucces > img {
  margin-top: 10px;
  width: 300px;
  height: 300px;
}

.orderSucessInfo {
  width: 400px;
  margin-top: 20px;
  margin-left: 35%;
  color: #1d1b1b;
}

.orderSucessInfoLeft {
  float: left;
  padding-bottom: 10px;
  width: 100px;
}

.orderSucessInfoRight {
  float: right;
  padding-bottom: 10px;
  color: #cb4255;
  font-weight: 700;
  width: 300px;
  text-align: right;
}
</style>
