<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w ">
      <ShopHeader />
      <div class="custoerAddressTitle">
        <h3>收货地址列表</h3>
      </div>
      <hr style="border: 1px solid #00a4ff;" />
      <div class="addresHeader">
        <el-button type="primary" @click="addAddressProp"
          >新增收货地址</el-button
        >
        <span style="color:#606266;margin-left:10px"
          >(每个账号最多10个收货地址)</span
        >
      </div>

      <el-card
        class="box-card"
        v-for="address in addressList"
        :key="address.deliveryAddressId"
      >
        <div slot="header">
          <span style="font-size:16px;font-weight:700;">{{
            address.consigneeName
          }}</span>
          <el-button
            style="float: right; color:#cb4255;"
            type="text"
            @click="addressDelConfirm(address.deliveryAddressId)"
            >删除</el-button
          >
          <el-button
            style="float: right; margin-right:10px;"
            type="text"
            @click="updateAddressProp(address)"
            >修改</el-button
          >
          <el-button
            style="float: right;margin-right:10px; "
            type="text"
            v-if="address.isDefaltAddress === 0"
            @click="setDefaultConfirm(address.deliveryAddressId)"
            >设为默认地址</el-button
          >
          <span v-else style="color:#cb4255; margin-left:20px;"
            >(默认地址)</span
          >
        </div>
        <div>
          <el-row style="margin:10px 0;">
            <el-col :span="2" style="text-align:right;">收货人:</el-col>
            <el-col :span="20" style="padding-left:50px;"
              >{{ address.consigneeName }}{{ address.consigneeNumber }}</el-col
            >
          </el-row>

          <el-row>
            <el-col :span="2" style="text-align:right;">收货地址:</el-col>
            <el-col :span="20" style="padding-left:50px;"
              >{{ address.provinceName }}{{ address.cityName
              }}{{ address.areaName }}{{ address.deliveryAddress }}</el-col
            >
          </el-row>
        </div>
      </el-card>

      <el-dialog
        :title="title"
        :visible.sync="addressDialogVisible"
        width="50%"
        :before-close="addressDoalogClose"
        center
      >
        <el-form
          ref="addressForm"
          :model="addressInfo"
          label-width="100px"
          :rules="addressRules"
        >
          <el-form-item label="收货人:" prop="consigneeName">
            <el-input
              v-model="addressInfo.consigneeName"
              style="width:400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码:" prop="consigneeNumber">
            <el-input
              v-model="addressInfo.consigneeNumber"
              style="width:400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在地区:" prop="areaId">
            <el-select
              v-model="addressInfo.provinceId"
              placeholder="请选择省"
              style="width:200px;"
              @change="changProvince"
            >
              <el-option
                v-for="item in provinceDate"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="addressInfo.cityId"
              placeholder="请选择市"
              style="width:200px;"
              @change="changCity"
            >
              <el-option
                v-for="item in cityDate"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="addressInfo.areaId"
              placeholder="请选择区"
              style="width:200px;"
              @change="changArea"
            >
              <el-option
                v-for="item in areaData"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="详细地址:" prop="deliveryAddress">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入详细地址"
              v-model="addressInfo.deliveryAddress"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addressDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAddress">确 定</el-button>
        </span>
      </el-dialog>

      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import ShopNavigation from "../components/ShopNavigation.vue";
export default {
  name: "CustomerAddress",
  components: {
    ShopHeader,
    XsjlInfo,
    ShopNavigation
  },
  data() {
    var validatorPhone = function(rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      addressList: [],
      addressDialogVisible: false,
      modifyAddressId: "",
      addressInfo: {
        consigneeName: "",
        consigneeNumber: "",
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
        deliveryAddress: ""
      },
      addressRules: {
        consigneeName: [
          { required: true, message: "请输入收货人", trigger: "blur" }
        ],
        consigneeNumber: [
          { required: true, validator: validatorPhone, trigger: "blur" }
        ],
        deliveryAddress: [
          { required: true, message: "请输入收货地址", trigger: "blur" }
        ],
        areaId: [{ required: true, message: "请选择省市区", trigger: "change" }]
      },
      provinceDate: [],
      cityDate: [],
      areaData: [],
      title: ""
    };
  },
  mounted() {
    this.getAddressList();
    this.getProvince();
  },
  methods: {
    async getAddressList() {
      const { data: res } = await this.$http.get("/cusAddress/list2");
      if (res.code === "K-000000") {
        this.addressList = res.context;
      } else {
        this.$message.error("获取用户收货地址列表失败");
      }
    },
    async getProvince() {
      const { data: res } = await this.$http.get("/cityInfo/province");

      if (res.code === "K-000000") {
        this.provinceDate = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    async getCity(provinceCode) {
      const { data: res } = await this.$http.get(
        "/cityInfo/city/" + provinceCode
      );

      if (res.code === "K-000000") {
        this.cityDate = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    async getArea(cityCode) {
      const { data: res } = await this.$http.get("/cityInfo/area/" + cityCode);
      console.log(res);
      if (res.code === "K-000000") {
        this.areaData = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    changProvince(provinceCode) {
      let proDate = this.provinceDate.filter(item => {
        return item.cityCode === provinceCode;
      });
      this.addressInfo.provinceName = proDate[0].cityName;
      this.getCity(provinceCode);
      this.addressInfo.cityId = "";
      this.cityDate = [];
      this.addressInfo.areaId = "";
      this.areaData = [];
    },
    changCity(cityCode) {
      let cityDate = this.cityDate.filter(item => {
        return item.cityCode === cityCode;
      });
      this.addressInfo.cityName = cityDate[0].cityName;
      this.getArea(cityCode);
      this.addressInfo.areaId = "";
      this.areaData = [];
    },
    changArea(areaCode) {
      let areaDate = this.areaData.filter(item => {
        return item.cityCode === areaCode;
      });
      this.addressInfo.areaName = areaDate[0].cityName;
    },
    saveAddress() {
      this.$refs.addressForm.validate(async valid => {
        // // 表单预校验失败
        if (!valid) return;

        if (this.modifyAddressId) {
          const { data: res } = await this.$http.post(
            "/cusAddress/updateAddressInfo",
            {
              consigneeName: this.addressInfo.consigneeName,
              consigneeNumber: this.addressInfo.consigneeNumber,
              provinceId: this.addressInfo.provinceId,
              provinceName: this.addressInfo.provinceName,
              cityId: this.addressInfo.cityId,
              cityName: this.addressInfo.cityName,
              areaId: this.addressInfo.areaId,
              areaName: this.addressInfo.areaName,
              deliveryAddress: this.addressInfo.deliveryAddress,
              deliveryAddressId: this.modifyAddressId
            }
          );
          if (res.code !== "K-000000") {
            return this.$message.error("修改收货地址失败!");
          }
          this.$message.success("修改收货地址成功!");
        } else {
          const { data: res } = await this.$http.post(
            "/cusAddress/add",
            this.addressInfo
          );
          if (res.code !== "K-000000") {
            return this.$message.error("新增收货地址失败!");
          }
          this.$message.success("新增收货地址成功!");
        }

        this.getAddressList();
        this.addressDoalogClose();
      });
    },
    addressDoalogClose() {
      this.addressDialogVisible = false;
      this.addressInfo = {
        consigneeName: "",
        consigneeNumber: "",
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
        deliveryAddress: ""
      };
      this.modifyAddressId = "";
    },
    setDefaultConfirm(deliveryAddressId) {
      this.$confirm("是否设置为默认收货地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setAddressDefault(deliveryAddressId);
        })
        .catch(() => {});
    },
    async setAddressDefault(deliveryAddressId) {
      const { data: res } = await this.$http.post("/cusAddress/setDefault", {
        deliveryAddressId: deliveryAddressId,
        isDefaltAddress: 1
      });
      if (res.code === "K-000000") {
        this.$message.success("设置默认收货地址成功");
        this.getAddressList();
      } else {
        this.$message.error("设置默认收货地址失败");
      }
    },
    addressDelConfirm(deliveryAddressId) {
      this.$confirm("是否删除收货地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.addressDel(deliveryAddressId);
        })
        .catch(() => {});
    },
    async addressDel(deliveryAddressId) {
      const { data: res } = await this.$http.post(
        "/cusAddress/delAddressInfo",
        {
          deliveryAddressId: deliveryAddressId,
          delFlag: 1
        }
      );
      if (res.code === "K-000000") {
        this.$message.success("删除收货地址成功");
        this.getAddressList();
      } else {
        this.$message.error("删除收货地址失败");
      }
    },
    updateAddressProp(address) {
      this.modifyAddressId = address.deliveryAddressId;
      this.addressInfo.consigneeName = address.consigneeName;
      this.addressInfo.consigneeNumber = address.consigneeNumber;
      this.addressInfo.provinceId = address.provinceId;
      this.addressInfo.provinceName = address.provinceName;
      this.addressInfo.cityId = address.cityId;
      this.addressInfo.cityName = address.cityName;
      this.addressInfo.areaId = address.areaId;
      this.addressInfo.areaName = address.areaName;
      this.addressInfo.deliveryAddress = address.deliveryAddress;
      this.getCity(address.provinceId);
      this.getArea(address.cityId);
      this.addressDialogVisible = true;
      this.title = "修改收货地址";
    },
    addAddressProp() {
      this.addressDialogVisible = true;
      this.title = "新增收货地址";
    }
  }
};
</script>

<style scoped>
.custoerAddressTitle {
  height: 45px;
  line-height: 45px;
}

.custoerAddressTitle > h3 {
  font-size: 20px;
  color: #494949;
  width: 150px;
}

.box-card {
  width: 100%;
  margin-top: 10px;
  color: #494949;
  font-size: 15px;
}

.addresHeader {
  height: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
}
</style>
