<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w ">
      <ShopHeader />

      <div class="shoppingcattitle">
        <h3>购物车列表</h3>
      </div>
      <hr style="border: 1px solid #00a4ff;" />
      <div class="confirmBody">
        <el-row>
          <el-col :span="2">收货地址:</el-col>
          <el-col :span="13">
            <el-select
              v-model="selectedCustomerAddress"
              placeholder="请选择收货地址"
            >
              <el-option
                v-for="item in customerAddressDate"
                :key="item.deliveryAddressId"
                :label="item.addressDetail"
                :value="item.deliveryAddressId"
              >
              </el-option> </el-select
          ></el-col>
          <el-col :span="2">
            <el-button
              type="danger"
              style="background-color:#cb4255;"
              @click="addressDialogVisible = true"
              >新增收货地址</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 65vw;margin-top:15px;"
        :header-row-style="{
          'font-size': '15px',
          color: '#00a4ff'
        }"
        @selection-change="handleSelectionChange"
        :row-style="{ height: '30px' }"
        stripe
      >
        <!-- <el-table-column type="selection" width="40"> </el-table-column> -->
        <el-table-column label="商品名称" width="490">
          <template slot-scope="scope">
            <div class="shoppingcargoodsimg">
              <el-image
                v-if="!scope.row.goodsImg"
                :src="defaultImg"
                :preview-src-list="defaultImgAry"
                style="width: 100px;height: 80px;float: left;overflow: auto;"
              />
              <el-image
                v-if="scope.row.goodsImg"
                :src="scope.row.goodsImg1"
                :preview-src-list="scope.row.goodsImgAry"
                style="width: 100px;height: 80px;float: left;overflow: auto;"
              />
              <div class="shoppingcargoodsname">
                <span> {{ scope.row.spmc }}{{ scope.row.spgg }} </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="数量" width="200" align="center">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.spNum"
              :min="1"
              label="描述文字"
              @change="shoppingCarNumChange(scope.row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="100" align="center">
          <template slot-scope="scope">
            <div class="shoppingcargoodsprice">￥{{ scope.row.spcbdj }}</div>
          </template>
        </el-table-column>
        <el-table-column label="合计" width="100" align="center">
          <template slot-scope="scope">
            <div class="shoppingcargoodsprice">
              ￥{{ scope.row.spcbdj * scope.row.spNum }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remarks"
              type="textarea"
              placeholder="可输入备注"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <a
              href="#"
              @click.prevent="removeGoods(scope.row.carId, scope.row.spNum)"
              >移除</a
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="shoppingcarjiesuan">
        <div class="goodszhonglei">
          商品种类<span style="color:#cb4255;font-weight:700;">{{
            goodsCateNum
          }}</span
          >种
        </div>
        <div class="goodsnumtotal">
          数量总计<span style="color:#cb4255;font-weight:700;">{{
            goodsNum
          }}</span
          >件
        </div>
        <div class="goodsnumtotal">
          合计：<span style="color:#cb4255;font-weight:700;"
            >￥{{ totalMoney }}</span
          >
        </div>
        <div class="goodsnumtotal">
          商品总额：<span style="color:#cb4255;font-weight:700;"
            >￥{{ totalMoney }}</span
          >
        </div>
        <div class="submitOrder" @click="submitOrder">
          <h3>订购</h3>
        </div>
      </div>

      <el-dialog
        title="新增收货地址"
        :visible.sync="addressDialogVisible"
        width="50%"
        :before-close="addressDoalogClose"
        center
      >
        <el-form
          ref="addressForm"
          :model="addressInfo"
          label-width="100px"
          :rules="addressRules"
        >
          <el-form-item label="收货人:" prop="consigneeName">
            <el-input
              v-model="addressInfo.consigneeName"
              style="width:400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="手机号码:" prop="consigneeNumber">
            <el-input
              v-model="addressInfo.consigneeNumber"
              style="width:400px"
            ></el-input>
          </el-form-item>

          <el-form-item label="所在地区:" prop="areaId">
            <el-select
              v-model="addressInfo.provinceId"
              placeholder="请选择省"
              style="width:200px;"
              @change="changProvince"
            >
              <el-option
                v-for="item in provinceDate"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="addressInfo.cityId"
              placeholder="请选择市"
              style="width:200px;"
              @change="changCity"
            >
              <el-option
                v-for="item in cityDate"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="addressInfo.areaId"
              placeholder="请选择区"
              style="width:200px;"
              @change="changArea"
            >
              <el-option
                v-for="item in areaData"
                :key="item.cityCode"
                :label="item.cityName"
                :value="item.cityCode"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="详细地址:" prop="deliveryAddress">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入详细地址"
              v-model="addressInfo.deliveryAddress"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addressDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveAddress">确 定</el-button>
        </span>
      </el-dialog>

      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import { mapMutations } from "vuex";
import ShopNavigation from "../components/ShopNavigation.vue";

export default {
  name: "ShoppingCar",
  components: {
    ShopHeader,
    XsjlInfo,
    ShopNavigation
  },
  data() {
    var validatorPhone = function(rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };

    return {
      tableData: [],
      multipleSelection: [],
      goodsCateNum: 0,
      goodsNum: 0,
      totalMoney: 0,
      customerAddressDate: [],
      selectedCustomerAddress: "",
      addressDialogVisible: false,
      addressInfo: {
        consigneeName: "",
        consigneeNumber: "",
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
        deliveryAddress: ""
      },
      addressRules: {
        consigneeName: [
          { required: true, message: "请输入收货人", trigger: "blur" }
        ],
        consigneeNumber: [
          { required: true, validator: validatorPhone, trigger: "blur" }
        ],
        deliveryAddress: [
          { required: true, message: "请输入收货地址", trigger: "blur" }
        ],
        areaId: [{ required: true, message: "请选择省市区", trigger: "change" }]
      },
      provinceDate: [],
      cityDate: [],
      areaData: [],
      defaultImg: require("../assets/img/goods01.jpg"),
      defaultImgAry: [require("../assets/img/goods01.jpg")]
    };
  },
  watch: {
    multipleSelection: {
      handler(val, oldVal) {
        this.goodsCateNum = this.multipleSelection.length;
        let num = 0,
          money = 0;
        this.multipleSelection.forEach(item => {
          num += item.spNum;
          money += item.spNum * item.spcbdj;
        });
        this.goodsNum = num;
        this.totalMoney = money;
      },
      deep: true
    }
  },
  mounted() {
    this.getShoppingCarList();
    this.getProvince();
    this.getCustomerAddressList();
  },
  methods: {
    ...mapMutations(["updateShoppingCarNum", "updateConfirmGoodsAry"]),
    async removeGoods(carId, spNum) {
      const { data: res } = await this.$http.post(
        "/shoppingcar/remove/" + carId
      );
      if (res.code === "K-000000") {
        this.tableData = res.context;
        this.updateShoppingCarNum(-spNum);
      } else {
        this.$message.error("删除购物车信息失败");
      }
      this.getShoppingCarList();
    },
    async getShoppingCarList() {
      const { data: res } = await this.$http.get("/shoppingcar/query");
      console.log(res);
      if (res.code === "K-000000") {
        this.tableData = res.context;
        if (this.tableData.length != 0) {
          this.tableData.forEach(item => {
            if (item.goodsImg && item.goodsImg.length > 5) {
              item.goodsImg1 = "http://jrsoft.jswcsrq.com:81" + item.goodsImg;
              item.goodsImgAry = [item.goodsImg1];
            }
          });
        }

        this.multipleSelection = res.context;
      } else {
        this.$message.error("获取购物车信息失败");
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection, "multipleSelection");
    },
    async submitOrder() {
      if (!this.selectedCustomerAddress) {
        return this.$message.error("请选择收货地址");
      }
      if (this.tableData.length == 0) {
        return this.$message.error("请添加商品后再订购");
      }

      let flag = false;
      for (let item of this.tableData) {
        if (parseInt(item.spcbdj) <= 0) {
          flag = true;
          break;
        }
      }
      if (flag) {
        return this.$message.error("商品价格有误,无法提交订单,请联系客户经理");
      }

      if (parseInt(this.totalMoney) <= 0) {
        return this.$message.error("订单金额有误,无法提交订单,请联系客户经理");
      }

      console.log(this.tableData);
      console.log(this.selectedCustomerAddress);
      const { data: res } = await this.$http.post("/order/submit", {
        shoppingCarIdList: this.tableData,
        addressId: this.selectedCustomerAddress,
        orderTotalMoney: this.totalMoney
      });
      if (res.code === "K-000001") {
        return this.$message.error("提交订单失败");
      }
      if (res.code === "K-000009") {
        return this.$message.error("收货地址无效");
      }
      if (res.code === "K-000008") {
        return this.$message.error("订单金额错误");
      }
      if (res.code === "K-000007") {
        return this.$message.error("存在非法参数");
      }

      if (res.code === "K-000000") {
        this.$router.push({
          name: "OrderSuccess",
          query: {
            orderCode: res.context.orderCode,
            money: res.context.money
          }
        });
      }
    },
    async shoppingCarNumChange(data) {
      const { data: res } = await this.$http.post("/shoppingcar/update", {
        carId: data.carId,
        num: data.spNum
      });
      console.log(res);
      if (res.code === "K-000000") {
      } else {
        this.$message.error("更新购物车商品数量失败");
      }
    },
    addressDoalogClose() {
      this.addressDialogVisible = false;
      this.addressInfo = {
        consigneeName: "",
        consigneeNumber: "",
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        areaId: "",
        areaName: "",
        deliveryAddress: ""
      };
    },
    async getProvince() {
      const { data: res } = await this.$http.get("/cityInfo/province");
      console.log(res);
      if (res.code === "K-000000") {
        this.provinceDate = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    async getCity(provinceCode) {
      const { data: res } = await this.$http.get(
        "/cityInfo/city/" + provinceCode
      );
      console.log(res);
      if (res.code === "K-000000") {
        this.cityDate = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    async getArea(cityCode) {
      const { data: res } = await this.$http.get("/cityInfo/area/" + cityCode);
      console.log(res);
      if (res.code === "K-000000") {
        this.areaData = res.context;
      } else {
        this.$message.error("获取省份信息失败");
      }
    },
    changProvince(provinceCode) {
      let proDate = this.provinceDate.filter(item => {
        return item.cityCode === provinceCode;
      });
      this.addressInfo.provinceName = proDate[0].cityName;
      this.getCity(provinceCode);
      this.addressInfo.cityId = "";
      this.cityDate = [];
      this.addressInfo.areaId = "";
      this.areaData = [];
    },
    changCity(cityCode) {
      let cityDate = this.cityDate.filter(item => {
        return item.cityCode === cityCode;
      });
      this.addressInfo.cityName = cityDate[0].cityName;
      this.getArea(cityCode);
      this.addressInfo.areaId = "";
      this.areaData = [];
    },
    changArea(areaCode) {
      let areaDate = this.areaData.filter(item => {
        return item.cityCode === areaCode;
      });
      this.addressInfo.areaName = areaDate[0].cityName;
    },
    saveAddress() {
      this.$refs.addressForm.validate(async valid => {
        // // 表单预校验失败
        if (!valid) return;

        const { data: res } = await this.$http.post(
          "/cusAddress/add",
          this.addressInfo
        );
        if (res.code !== "K-000000") {
          return this.$message.error("新增收货地址失败!");
        }
        this.$message.success("新增收货地址成功!");
        let addressDetail =
          this.addressInfo.consigneeName +
          " " +
          this.addressInfo.consigneeNumber.substring(0, 4) +
          "****" +
          this.addressInfo.consigneeNumber.substring(7) +
          " " +
          this.addressInfo.provinceName +
          this.addressInfo.cityName +
          this.addressInfo.areaName +
          this.addressInfo.deliveryAddress;
        this.customerAddressDate.push({
          addressDetail: addressDetail,
          deliveryAddressId: res.context,
          isDefault: 0
        });
        this.addressDoalogClose();
      });
    },
    async getCustomerAddressList() {
      const { data: res } = await this.$http.get("/cusAddress/list");
      if (res.code === "K-000000") {
        this.customerAddressDate = res.context;
        if (this.customerAddressDate.length === 1) {
          this.selectedCustomerAddress = this.customerAddressDate[0].deliveryAddressId;
        } else {
          let address = this.customerAddressDate.filter(item => {
            return item.isDefault === 1;
          });
          if (address.length === 1) {
            this.selectedCustomerAddress = address[0].deliveryAddressId;
          }
        }
      } else {
        this.$message.error("获取用户收货地址列表失败");
      }
    }
  }
};
</script>

<style scoped>
.shoppingcargoodsimg > img {
  float: left;
  width: 100px;
  height: 80px;
  overflow: auto;
}
.shoppingcargoodsname {
  float: left;
  line-height: 70px;
  font-size: 16px;
  overflow: hidden;
}
.shoppingcargoodsname span {
  float: left;
  word-wrap: break-word;
}

.shoppingcargoodsprice {
  color: #cb4255;
  font-weight: 700;
  font-size: 16px;
}

.shoppingcarjiesuan {
  position: sticky;
  z-index: 5;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: right;
  background-color: #dbe1e7;
}

.goodszhonglei {
  float: left;
  width: 200px;
  text-align: center;
  color: #00a4ff;
  font-size: 14px;
}

.goodsnumtotal {
  float: left;
  width: 200px;
  text-align: center;
  color: #00a4ff;
  font-size: 14px;
}

.submitOrder {
  float: right;
  width: 150px;
  height: 60px;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  background-color: #cb4255;
}

.shoppingcattitle {
  height: 45px;
  line-height: 45px;
}

.shoppingcattitle > h3 {
  font-size: 20px;
  color: #494949;
  width: 150px;
}

.confirmBody {
  margin-top: 10px;
  color: #565353;
  font-size: 16px;
}

.el-row {
  margin-bottom: 10px;
  margin-top: 10px;
}
.el-col {
  line-height: 36px;
}

.el-select {
  width: 32vw;
}
</style>
