import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shoppingCarNum: 0,
    confirmGoodsAry:[],
    xieLeiAry:[]
  },
  mutations: {
    updateShoppingCarNum(state,num){
      state.shoppingCarNum+=num
    },
    initShoppingCarNum(state,num){
      state.shoppingCarNum=num
    },
    updateConfirmGoodsAry(state,ary){
      if(ary.length!==0){
        state.confirmGoodsAry=[...ary];
      }else{
        state.confirmGoodsAry=[]
      }
    },
    updateXieLeiAry(state,ary){
      state.xieLeiAry=[...ary];
    }
  },
  actions: {
  },
  modules: {
  },
  getters:{
    getTotalMoney(state){
      if(state.confirmGoodsAry.length!==0){
        return state.confirmGoodsAry.reduce((total,item)=>{
          return (item.spcbdj * item.spNum)+total
        },0)
      }
      return 0;
    }
  }
})
