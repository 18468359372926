<template>
  <div class="w">
    <div class="error500">
      <img :src="img" />
      <a href="#" @click.prevent="back">返回首页</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "error500",
  data() {
    return {
      img: require("../assets/img/500.png")
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: "Login"
      });
    }
  }
};
</script>

<style scoped>
.error500 {
  width: 1000px;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  text-align: center;
}

.error500 > img {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.error500 > a {
}
</style>
