<template>
  <div class="allLoading">
    <div class="w ">
      <div class="shopheader">
        <div class="logo" @click="bakcIndex">
          <img src="../assets/img/logo.png" />
        </div>

        <div class="shopsearch">
          <el-input
            type="text"
            v-model="query.keyWorld"
            placeholder="输入柴油机型号"
            style="width:12vw;float:left;margin-right:3px;"
          />
          <el-select
            v-model="query.xiLeiName"
            placeholder="请选择系列"
            style="float:left;margin-right:3px; width:12vw;"
            filterable
          >
            <el-option
              v-for="item in xieLeiAry"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <!-- <button @click="search"></button> -->
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="searchGoods"
          ></el-button>
        </div>
      </div>
      <div class="box2">
        <div class="box2_hd">
          <div style="float:left;">
            <h3>商品搜索</h3>
          </div>
          <div style="float:rigth;text-align:right">
            销售热线：<span style="color:#00a4ff;font-weight:700;"
              >400-180-4088</span
            >
          </div>
        </div>
        <hr style="border: 1px solid #00a4ff;" />
        <div class="box2_bd" v-if="totalNum !== 0">
          <ul class="clearfix">
            <li v-for="goods in goodsDate" :key="goods.spbh">
              <img v-if="!goods.img" :src="defaultImg" />
              <img v-if="goods.img" :src="goods.img1" />
              <h4>{{ goods.spmc }}</h4>
              <h4>{{ goods.spgg }}</h4>
              <div class="goodsSearch_info">
                <!--<span>¥{{ goods.spcbdj }}</span>
                 <el-input-number
                  size="mini"
                  v-model="goods.carNum"
                  style="margin-left:15px;width:100px;"
                  :min="1"
                ></el-input-number>
                <el-button
                  style="float: right; font-size: 16px;position: relative;top:-8px;"
                  type="text"
                  >预订</el-button
                >
                <a href="#" @click.prevent="addShoppingCar(goods.spbh)"
                  >加入我的预订</a
                > -->
              </div>
            </li>
          </ul>
        </div>
        <div class="goodsfengye" v-if="totalNum !== 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalNum"
            :page-size="query.pageSize"
            :current-page="query.pageNum"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="noGoodsSearch" v-if="goodsDate.length === 0">
        商品系列型号全面,品种过于繁多,页面不能一一展示,请输入具体型号或系列查询。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TouristSearch",
  data() {
    return {
      xieLeiAry: [],
      query: {
        keyWorld: "",
        xiLeiName: "",
        pageNum: 1,
        pageSize: 30
      },
      totalNum: 0,
      defaultImg: require("../assets/img/goods01.jpg"),
      goodsDate: []
    };
  },
  mounted() {
    this.getTouristXiLei();
  },
  methods: {
    bakcIndex() {
      this.$router.push({
        name: "Login"
      });
    },
    async getTouristXiLei() {
      const { data: res } = await this.$http.get("/goods/touristXiLei");
      if (res.code === "K-000000") {
        this.xieLeiAry = res.context;
      } else {
        this.$message.error("获取商品系列数据失败");
      }
    },
    async searchGoods() {
      const { data: res } = await this.$http.post(
        "/goods/touristSearch",
        this.query
      );
      if (res.code === "K-000000") {
        this.goodsDate = res.context.records;
        this.goodsDate.forEach(item => {
          if (item.img && item.img.length > 5) {
            item.img1 = "http://jrsoft.jswcsrq.com:81" + item.img;
          }
        });
        this.totalNum = res.context.total;
      }
    },
    handleCurrentChange(newPageNum) {
      this.query.pageNum = newPageNum;
      this.searchGoods();
    }
  }
};
</script>

<style scoped>
.shopheader {
  height: 4vw;
}

.logo {
  float: left;
  /* margin-left: 20px; */
}

.logo > img {
  width: 11vw;
  cursor: pointer;
}

.shopsearch {
  float: left;
  width: 29vw;
  height: 4vh;
  margin-left: 5vw;
  margin-top: 1vw;
}

.shopsearch input {
  float: left;
  width: 12.2vw;
  height: 3.5vh;
  border: 1px solid;
  border-right: 0;
  font-size: 14px;
  padding-left: 15px;
  color: #bfbfbf;
  border-radius: 7px;
}

.noGoodsSearch {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-top: 30px;
}

.box2_bd .goodsSearch_info {
  margin: 20px 10px;
}

.box2_bd .goodsSearch_info span {
  color: #cb4255;
  font-size: 13px;
  font-weight: 700;
}

.goodsfengye {
  text-align: center;
  margin-top: 20px;
}
</style>
