<template>
  <div class="shopheadernavigation">
    <div class="w">
      <ul>
        <!-- <li><a href="#" @click.prevent="goto('useraddress')">收货地址</a></li>
        <li><a href="#" @click.prevent="goto('useraccount')">账号管理</a></li>
        <li><a href="#">新品推荐</a></li>
        <li><a href="#">特惠捡漏</a></li>
        <li><a href="#" @click.prevent="goto('order')">订单查询</a></li>
        <li><a href="#">账务往来</a></li> -->
        <li v-for="item in menusAry" :key="item.menuId">
          <a href="#" @click.prevent="goto(item.menuUrl)">{{
            item.menuName
          }}</a>
        </li>
        <li>
          <a href="#" @click.prevent="goto('home')" style="border-left: 0px;"
            >首页</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopNavigation",
  data() {
    return {
      menusAry: []
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    goto(type) {
      if ("order" === type) {
        this.$router.push({
          name: "MyOrder"
        });
      }
      if ("useraccount" === type) {
        this.$router.push({
          name: "WxAccuntManage"
        });
      }
      if ("home" === type) {
        this.$router.push({
          name: "Home"
        });
      }
      if ("useraddress" === type) {
        this.$router.push({
          name: "CustomerAddress"
        });
      }
    },
    async getMenus() {
      const { data: res } = await this.$http.get("/userMenu/userMenus");
      if (res.code === "K-000000") {
        this.menusAry = res.context;
      }
    }
  }
};
</script>

<style scoped>
.shopheadernavigation {
  height: 22px;
  border-bottom: 1px solid #ddd;
  background-color: #e3e4e5;
  margin-bottom: 10px;
}

.shopheadernavigation > ul {
  float: right;
  margin-left: 30px;
}

.shopheadernavigation ul li {
  float: right;
}

.shopheadernavigation ul li a {
  padding: 0 15px;
  font-size: 14px;
  color: black;
  border-left: 1px solid #ccc;
  color: #606266;
}
</style>
