<template>
  <div class="hello">
    <div class="floor1 w">
      <div class="floor1_box1" @click="goto('Shop')">
        <img :src="sp2" />
        <h4>维创散热器订购下单</h4>
      </div>

      <div class="floor1_box2" @click="goto('MyOrder')">
        <img :src="sp4" />
        <h4>订单查询</h4>
      </div>

      <div class="floor1_box3">
        <img :src="sp3" />
        <h4>账务往来</h4>
      </div>

      <div class="floor1_box2" style=" background-color:#246ac9">
        <img :src="sp5" />
        <h4>新品推荐</h4>
      </div>

      <div class="floor1_box3" style=" background-color:#bf44c1">
        <img :src="sp6" />
        <h4>特惠捡漏</h4>
      </div>
    </div>

    <div class="floor3 w">
      <ul>
        <li>
          <a
            href="http://www.qdwss.com/index.php?g=Wap&m=Index&a=lists&classid=13185&token=oxjlsc1412663362&wecha_id=orJTvsjQz1lqcPrmmTQW7mKYJZ5Q"
          >
            <img :src="sp1" class="khzc" />
            <h4>客户支持</h4>
          </a>
        </li>
        <li>
          <a href="#" @click.prevent="showGsjj">
            <img :src="sp8" class="gsjz" />
            <h4>公司简介</h4></a
          >
        </li>
        <li style="float:right;margin-right: 0px; width:18.27vw;">
          <a href="#">
            <el-image
              :src="sp7"
              class="lxll"
              :preview-src-list="defaultImgAry"
            ></el-image>
            <!-- <h4>联系联络</h4> -->
          </a>
        </li>
      </ul>
    </div>

    <el-dialog
      title
      :visible.sync="dialogPlay"
      width="70%"
      :modal="false"
      :fullscreen="false"
      @close="closeDialog"
      style="width:65vw; margin-left:20vw"
    >
      <video
        :src="videoUrl"
        controls
        autoplay
        width="100%"
        height="100%"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MainBody",
  data() {
    return {
      sp1: require("../assets/img/jszc.png"),
      sp2: require("../assets/img/sp2.png"),
      sp3: require("../assets/img/ywwl.png"),
      sp4: require("../assets/img/orderquery.png"),
      sp5: require("../assets/img/new.png"),
      sp6: require("../assets/img/youhui.png"),
      sp7: require("../assets/img/lxluo.png"),
      sp8: require("../assets/img/gs.png"),
      videoUrl: "",
      dialogPlay: false,
      defaultImgAry: [require("../assets/img/xiaoshoulist.jpg")]
    };
  },
  methods: {
    closeDialog() {
      this.videoUrl = "";
    },
    jszc() {
      this.$router.push({
        name: "Jszc"
      });
    },
    goto(type) {
      let token = window.sessionStorage.getItem("token");
      if (token && token.length != 0) {
        this.$router.push({
          name: type
        });
      } else {
        this.$router.push({
          name: "Login"
        });
      }
    },
    showGsjj() {
      this.dialogPlay = true;
      this.$nextTick(() => {
        this.videoUrl = require("../assets/video/gsjj.mp4");
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.floor1 {
  margin-top: 10px;
}

.floor1_box1 {
  float: left;
  width: 27vw;
  height: 27.5vh;
  background-color: #14aab8;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.floor1_box1 > img {
  width: 16.2vw;
  height: 16vh;
  /* margin: auto 0; */
  margin-top: 26px;
}

.floor1_box1 > h4 {
  font-size: 1.7vw;
  color: white;
}

.floor1_box2 {
  float: right;
  width: 18.3vw;
  height: 13.2vh;
  background-color: #11ac13;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.floor1_box2 > img {
  width: 7.8vw;
  height: 11.3vh;
  float: left;
}

.floor1_box2 > h4 {
  font-size: 1.7vw;
  color: white;
  line-height: 11.3vh;
}

.floor1_box3 {
  float: right;
  width: 18.3vw;
  height: 13.2vh;
  background-color: #246ac9;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.floor1_box3 > img {
  width: 7.8vw;
  height: 11.3vh;
  float: left;
}

.floor1_box3 > h4 {
  font-size: 1.7vw;
  color: white;
  line-height: 11.3vh;
}

.floor2 {
  margin-top: 10px;
  height: 150px;
  cursor: pointer;
}

.floor2_box1 {
  float: left;
  width: 595px;
  height: 150px;
  background-color: #00ccff;
  border-radius: 15px;
  text-align: center;
}

.floor2_box1 > img {
  width: 80%;
  height: 100%;
}

.floor2_box2 {
  float: right;
  width: 595px;
  height: 150px;
  background-color: #2b87ee;
  border-radius: 10px;
  text-align: center;
}

.floor2_box2 > img {
  width: 150px;
  height: 100px;
}

.floor2_box2 > h4 {
  font-size: 25px;
  color: white;
}

.floor3 {
  margin-top: 10px;
}

.floor3 ul {
  float: left;
  width: 65vw;
  text-align: center;
}

.floor3 ul li {
  float: left;
  width: 22.4vw;
  height: 16.5vh;
  margin-right: 0.8vw;
  border-radius: 10px;
}

.floor3 ul li a {
  text-align: center;
  color: aliceblue;
}

.floor3 ul li a > img {
}

.floor3 ul li a > h4 {
  font-size: 1.7vw;
  line-height: 15vh;
}

.floor3 ul li:nth-child(1) {
  background-color: #04d3ff;
}

.floor3 ul li:nth-child(2) {
  background-color: #04d3ff;
}
.floor3 ul li:nth-child(3) {
  background-color: #e67d12;
}

.khzc {
  width: 8.7vw;
  height: 16.5vh;
  float: left;
}

.gsjz {
  width: 8.7vw;
  height: 16.5vh;
  float: left;
}

.lxll {
  width: 18.3vw;
  height: 16.5vh;
  float: left;
}
</style>
