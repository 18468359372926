<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w ">
      <ShopHeader />
      <div v-for="item in xilieAry" :key="item.xiLieName">
        <div class="boxsix">
          <div class="boxsixleft">
            <img :src="item.xiLeiImg" />
            <div class="xieliename">{{ item.xiLieName }}铝</div>
          </div>
          <div class="boxsixright">
            <ul class="clearfix">
              <li v-for="subitem in item.goodsList" :key="subitem.spbh">
                <el-dropdown
                  trigger="click"
                  @visible-change="handleCommand($event, subitem, item)"
                  ref="dropdown"
                >
                  <span class="el-dropdown-link">
                    <el-badge
                      :value="subitem.shoppingCarNum"
                      v-if="subitem.shoppingCarNum !== 0"
                      style="display: block"
                    >
                      <el-tooltip
                        class="item"
                        effect="light"
                        placement="top-start"
                      >
                        <div slot="content">
                          柴油机型号:{{ subitem.spspjx }}<br />水箱型号:{{
                            subitem.spgg
                          }}
                        </div>
                        <div
                          :class="
                            subitem.shoppingCarNum === 0
                              ? 'shopxiaoshouxh'
                              : 'shopxiaoshouxh2'
                          "
                        >
                          {{ subitem.spspjx }}
                          <hr style="border:0.5px solid #00a4ff" />
                          {{ subitem.spgg }}
                        </div>
                      </el-tooltip>
                    </el-badge>
                    <el-tooltip
                      class="item"
                      effect="light"
                      placement="top-start"
                    >
                      <div slot="content">
                        柴油机型号:{{ subitem.spspjx }}<br />水箱型号:{{
                          subitem.spgg
                        }}
                      </div>
                      <div
                        class="shopxiaoshouxh"
                        v-if="subitem.shoppingCarNum === 0"
                      >
                        {{ subitem.spspjx }}
                        <br />
                        {{ subitem.spgg }}
                      </div>
                    </el-tooltip>
                  </span>

                  <el-dropdown-menu
                    slot="dropdown"
                    style="width:200px;top:170px;background-color: whitesmoke;"
                  >
                    <el-input-number
                      size="small"
                      v-model="carNum"
                      :min="1"
                      style="margin-left:15px;"
                    ></el-input-number>
                    <el-button
                      style="float: right; margin-right: 10px; font-size: 16px;position: relative;top:-5px;"
                      type="text"
                      @click="
                        addShoppingCar(subitem.spbh, subitem.orderNum, subitem)
                      "
                      >预订</el-button
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>
        <hr style="border: 1px solid #00a4ff;margin-top:20px" />
      </div>
      <XsjlInfo />
      <!-- <div class="xiaoshoujinliinfoleft"></div> -->
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
import ShopNavigation from "../components/ShopNavigation.vue";
import { mapMutations } from "vuex";
export default {
  name: "Shop",
  data() {
    return {
      xilieAry: [],
      carNum: 1,
      defaultImg: require("../assets/img/goods01.jpg")
    };
  },
  components: {
    ShopHeader,
    XsjlInfo,
    ShopNavigation
  },
  mounted() {
    this.getAllXiLie();
  },
  methods: {
    ...mapMutations(["updateShoppingCarNum", "updateXieLeiAry"]),
    async getAllXiLie() {
      const { data: res } = await this.$http.get("/goods/xilei");
      console.log(res);
      if (res.code === "K-000000") {
        this.xilieAry = res.context;
        if (this.xilieAry.length !== 0) {
          this.xilieAry.forEach((item, index) => {
            if (item.goodsList[0].img) {
              item.xiLeiImg =
                "http://jrsoft.jswcsrq.com:81" + item.goodsList[0].img;
            } else {
              item.xiLeiImg = this.defaultImg;
            }
          });
        }
        if (res.context.length !== 0) {
          let newAry = new Array();
          res.context.forEach(element => {
            newAry.push(element.xiLieName);
          });
          this.updateXieLeiAry(newAry);
        } else {
          this.updateXieLeiAry(new Array());
        }
      }
    },
    searchGoods(xilieName) {
      this.$router.push({
        name: "GoodSearch",
        query: {
          keyWorld: xilieName,
          searchType: 0
        }
      });
    },
    async addShoppingCar(spbh, orderNum, subitem) {
      // if (subitem.shoppingCarNum === 0) {
      //   return this.$message.error("购买商品数量不能为零");
      // }
      if (parseInt(subitem.spprice) <= 0) {
        return this.$message.error("商品销售价有误,请联系客户经理");
      }
      if (this.carNum === subitem.shoppingCarNum) {
        this.$refs.dropdown[orderNum].visible = false;
        return this.$message.success("成功加入我的预订商品");
      }
      let num = this.carNum - subitem.shoppingCarNum;
      const { data: res } = await this.$http.post("/shoppingcar/add", {
        spbh: spbh,
        spNum: num,
        delFlag: 1
      });
      if (res.code === "K-000000") {
        this.updateShoppingCarNum(num);
        this.$refs.dropdown[orderNum].visible = false;
        subitem.shoppingCarNum = this.carNum;
        this.$message.success("成功加入我的预订商品");
      } else {
        this.$message.error("加入我的预订商品失败");
      }
    },
    handleCommand(flag, subitem, item) {
      console.log(flag, subitem, item);
      if (flag) {
        this.carNum = subitem.shoppingCarNum;
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          if (subitem.img && subitem.img.length > 5) {
            item.xiLeiImg = "http://jrsoft.jswcsrq.com:81" + subitem.img;
          } else {
            item.xiLeiImg = this.defaultImg;
          }
        });
      }
    }
  }
};
</script>

<style scope>
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}

.catetory {
  background-color: #fff;
  margin-top: 20px;
  line-height: 50px;
  box-shadow: 0 2px 3px 3px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.catetory ul {
  float: left;
  margin-left: 30px;
}

.catetory ul li {
  float: left;
}

.catetory ul li a {
  padding-right: 10px;
  font-size: 16px;
  color: #00a4ff;
}

.box2 {
  margin-top: 18px;
}

.box2_hd {
  height: 45px;
  line-height: 45px;
}

.box2_hd h3 {
  float: left;
  font-size: 20px;
  color: #494949;
  width: 150px;
}

.box2_hd a {
  float: right;
  margin-right: 30px;
  font-size: 16px;
  color: #00a4ff;
}

.box2_bd ul {
  width: 65vw;
}
.box2_bd ul li {
  float: left;
  width: 12.2vw;
  height: 30vh;
  margin-right: 0.8vw;
  background-color: #fff;
  margin-top: 0.9vh;
  cursor: pointer;
  border-radius: 7px;
}
.box2_bd ul li img {
  width: 100%;
  height: 18.2vh;
}

.box2_bd ul li h4 {
  margin: 0px 10px;
  font-size: 13px;
  font-weight: 400px;
  text-align: center;
}

.box2_bd .info {
  margin: 20px 20px;
}

.box2_bd .info a {
  float: right;
  color: #00a4ff;
}

.box2_bd .info span {
  color: #cb4255;
  font-size: 18px;
  font-weight: 700;
}

.el-dropdown-menu {
  left: 154px;
}

.xiliexinghao {
  float: left;
  margin-left: 30px;
  overflow: auto;
}

.xiliexinghao li {
  float: left;
}

.xiliexinghao li a {
  padding-right: 10px;
  font-size: 14px;
}

.boxsix {
  margin-top: 20px;
  width: 1200px;
  overflow: auto;
}

.boxsixleft {
  width: 300px;
  float: left;
  margin-right: 20px;
  height: 100%;
}

.boxsixleft > img {
  width: 300px;
  height: 300px;
  border-radius: 7px;
}

.xieliename {
  text-align: center;
  font-weight: 700;
  line-height: 60px;
  font-size: 20px;
}

.boxsixright {
  width: 880px;
  float: right;
  height: 100%;
}

.boxsixright > ul {
  float: left;
}

.boxsixright ul li {
  float: left;
  margin: 4px 0;
}

.shopxiaoshouxh {
  font-size: 16px;
  color: #00a4ff;
  border: 1px solid #00a4ff;
  height: 60px;
  cursor: pointer;
  margin-right: 5px;
  width: 200px;
  word-break: break-all;
  overflow: hidden;
  text-align: center;
  border-radius: 7px;
}

.shopxiaoshouxh2 {
  font-size: 16px;
  color: #00a4ff;
  border: 1px solid #00a4ff;
  height: 60px;
  cursor: pointer;
  margin-right: 5px;
  width: 200px;
  word-break: break-all;
  overflow: hidden;
  text-align: center;
  border-radius: 7px;
  background-color: #d6eafd;
}

.xiaoshoujinliinfoleft {
  position: fixed;
  z-index: 2008;
  /* bottom: 20px; */
  left: 5%;
  width: 100px;
  height: 200px;
  border: 1px solid red;
  background: #e5e5e5;
  /* overflow-y: scroll; */
  /* overflow-x: auto; */
  top: 40%;
}

.el-badge__content.is-fixed {
  position: absolute !important;
  top: 6px !important;
  right: 24px !important;
  transform: translateY(-50%) translateX(100%) !important;
}

.el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #1875d5 !important;
}
</style>
