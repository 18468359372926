import Vue from 'vue'
import { Button,Form,FormItem,Input,Message,MessageBox,Dropdown,DropdownMenu,DropdownItem,Dialog,Card,DatePicker} from 'element-ui'
import { Loading,Table,TableColumn,InputNumber,Pagination,Badge,Select,Option,Row,Col,Radio,Tabs,TabPane,Popconfirm,Image,Tooltip } from 'element-ui';

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Loading)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(InputNumber)
Vue.use(Pagination)
Vue.use(Badge)
Vue.use(Select)
Vue.use(Option)
Vue.use(Row)
Vue.use(Col)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Popconfirm)
Vue.use(Card)
Vue.use(DatePicker)
Vue.use(Image)
Vue.use(Tooltip)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message= Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert