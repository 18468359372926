import { render, staticRenderFns } from "./XsjlInfo.vue?vue&type=template&id=46def721&scoped=true&"
import script from "./XsjlInfo.vue?vue&type=script&lang=js&"
export * from "./XsjlInfo.vue?vue&type=script&lang=js&"
import style0 from "./XsjlInfo.vue?vue&type=style&index=0&id=46def721&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46def721",
  null
  
)

export default component.exports