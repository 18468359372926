<template>
  <div class="shopheader">
    <div class="logo" @click="bakcIndex">
      <img src="../assets/img/logo.png" />
    </div>

    <div class="shopsearch">
      <el-input
        type="text"
        v-model="keyWorld"
        placeholder="输入柴油机型号"
        style="width:12vw;float:left;margin-right:3px;"
      />
      <el-select
        v-model="xiLeiName"
        placeholder="请选择系列"
        style="float:left;margin-right:3px; width:12vw;"
      >
        <el-option
          v-for="item in xieLeiAry"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <!-- <button @click="search"></button> -->
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="search"
      ></el-button>
    </div>

    <div class="shopuser">
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link" style="font-size:16px;">
          <i class="el-icon-s-custom"></i>
          {{ khmc }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="order">我的订单</el-dropdown-item> -->
          <el-dropdown-item command="password">修改密码</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="shoppingcar">
      <!-- <i
        class="el-icon-shopping-cart-full"
        style="float:left; margin-top:15px;margin-left:10px;"
      ></i> -->
      <el-badge :value="shoppingCarNum" class="item">
        <!-- <h4>我的预订商品</h4> -->
        <el-button
          type="primary"
          icon="el-icon-shopping-cart-2"
          @click="goShoppingCar"
          >预订商品</el-button
        >
      </el-badge>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ShopHeader",
  data() {
    return {
      khmc: "",
      keyWorld: this.$route.query.keyWorld,
      curPath: this.$route.path,
      xiLeiName: this.$route.query.xiLeiName,
      isMain: "",
      unionId: ""
    };
  },
  computed: {
    ...mapState(["shoppingCarNum", "xieLeiAry"])
  },
  mounted() {
    var userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.khmc = userInfo.khmc;
    this.isMain = userInfo.isMain;
    this.unionId = userInfo.unionId;
    this.getShoppingCarCount();
  },
  methods: {
    async getShoppingCarCount() {
      const { data: res } = await this.$http.get("/shoppingcar/count");
      if (res.code === "K-000000") {
        this.$store.commit("initShoppingCarNum", res.context);
      } else {
        this.$message.error("获取购物车数量失败");
      }
    },
    search() {
      if (!this.keyWorld && !this.xiLeiName) {
        return this.$message.error("请输入搜索关键字或选择系列");
      }
      if (this.curPath.indexOf("goodsearch") !== -1) {
        this.$parent.searchGoodsKeyWorld(this.keyWorld, 1, this.xiLeiName);
      } else {
        this.$router.push({
          name: "GoodSearch",
          query: {
            keyWorld: this.keyWorld,
            searchType: 1,
            xiLeiName: this.xiLeiName
          }
        });
      }
    },
    bakcIndex() {
      this.$router.push({
        name: "Shop"
      });
    },
    goShoppingCar() {
      this.$router.push({
        name: "ShoppingCar"
      });
    },
    handleCommand(command) {
      if (command === "logout") {
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("userInfo");
        this.$router.push({
          name: "Login"
        });
      }
      if (command === "order") {
        this.$router.push({
          name: "MyOrder"
        });
      }
      if (command === "password") {
        console.log(this.isMain);
        if (1 === this.isMain) {
          if (this.unionId) {
            this.$router.push({
              name: "PasswordUpdate2",
              query: {
                unionId: this.unionId
              }
            });
          } else {
            window.location.href =
              "https://open.weixin.qq.com/connect/qrconnect?appid=wx35ee275a405dab26&redirect_uri=http%3A%2F%2Fclient.jswcsrq.com%2Fshop%2Fuser%2Fwxlogin&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
          }
        } else {
          this.$confirm(
            "此操作需要主账号手机微信扫码确认后方可操作, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              window.location.href =
                "https://open.weixin.qq.com/connect/qrconnect?appid=wx35ee275a405dab26&redirect_uri=http%3A%2F%2Fclient.jswcsrq.com%2Fshop%2Fuser%2Fwxlogin&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
            })
            .catch(() => {});
        }
      }
    }
  }
};
</script>

<style scoped>
.shopheader {
  height: 4vw;
}

.logo {
  float: left;
  /* margin-left: 20px; */
}

.logo > img {
  width: 11vw;
  cursor: pointer;
}

.shopsearch {
  float: left;
  width: 29vw;
  height: 4vh;
  margin-left: 5vw;
  margin-top: 1vw;
}

.shopsearch input {
  float: left;
  width: 12.2vw;
  height: 3.5vh;
  border: 1px solid;
  border-right: 0;
  font-size: 14px;
  padding-left: 15px;
  color: #bfbfbf;
  border-radius: 7px;
}

/* .shopsearch button {
  float: left;
  background-image: url(../assets/img/btn.png);
  width: 2.8vw;
  height: 4.4vh;
  border: 0;
  cursor: pointer;
  border-radius: 7px;
} */

.shoppingcar {
  float: right;
  width: 6.4vw;
  height: 4vh;
  /* border: 1px solid #00a4ff; */
  font-size: 0.8vw;
  color: #00a4ff;
  margin-top: 1vw;
  text-align: center;
  line-height: 4vh;
  cursor: pointer;
  border-radius: 7px;
  margin-right: 2.7vw;
}

.shopuser {
  float: right;
  margin-right: 10px;
  color: #bfbfbf;
  height: 42px;
  margin-top: 28px;
  cursor: pointer;
  width: 10vw;
}
</style>
