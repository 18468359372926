<template>
  <div class="allLoading">
    <ShopNavigation />
    <div class="w ">
      <ShopHeader />
      <div class="box2">
        <div class="box2_hd">
          <h3>账号管理</h3>
        </div>
        <hr style="border: 1px solid #00a4ff;" />
        <el-input
          v-model="query.userName"
          style="width:200px;margin-right:10px;"
          placeholder="姓名"
        ></el-input>

        <el-input
          v-model="query.userPhone"
          style="width:300px;margin:15px 0;;"
          placeholder="手机号码"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchList"
          ></el-button>
        </el-input>

        <el-button
          type="primary"
          style="margin-left:10px"
          @click="dialogVisible = true"
          size="medium"
          v-if="isMain === 1"
          >新增子账号</el-button
        >

        <el-table
          :data="tableData"
          style="width: 100%"
          :cell-style="aligncenter"
          stripe
        >
          <el-table-column label="姓名" header-align="center" prop="khfzr" />

          <el-table-column label="手机号码" header-align="center" prop="khsj" />
          <el-table-column
            prop="ninckName"
            label="微信昵称"
            header-align="center"
          >
          </el-table-column>

          <el-table-column label="操作" header-align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="userInfoShowDialog(scope.row)"
                size="medium"
                v-if="isMain === 1"
                >修改</el-button
              >
              <el-button
                type="danger"
                @click="remove(scope.row)"
                size="medium"
                v-if="isMain === 1"
                >移除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="goodsfengye">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="totalNum"
            :page-size="query.pageSize"
            :current-page="query.pageNum"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <ShopFooter /> -->

      <el-dialog
        title="新增子账号"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
        style="margin-top:50px;"
      >
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
          <el-form-item label="姓名:" prop="userName">
            <el-input v-model="form.userName" style="width:300px;"></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="userPhone">
            <el-input v-model="form.userPhone" style="width:300px;"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="userPsw">
            <el-input
              v-model="form.userPsw"
              type="password"
              style="width:300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="userPsw2">
            <el-input
              v-model="form.userPsw2"
              type="password"
              style="width:300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限设置:" prop="menuIds">
            <el-select v-model="form.menuIds" multiple placeholder="请选择">
              <el-option
                v-for="item in menusAry"
                :key="item.menuId"
                :label="item.menuName"
                :value="item.menuId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="addNewAccount">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="修改子账号"
        :visible.sync="modifyDialogVisible"
        width="50%"
        :before-close="userInfoHandleClose"
        style="margin-top:50px;"
      >
        <el-form
          ref="modifyform"
          :model="modform"
          label-width="100px"
          :rules="modifyules"
        >
          <el-form-item label="姓名:" prop="userName">
            <el-input
              v-model="modform.userName"
              style="width:300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="userPhone">
            <el-input
              v-model="modform.userPhone"
              style="width:300px;"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="密码:" prop="userPsw">
            <el-input
              v-model="form.userPsw"
              type="password"
              style="width:300px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="userPsw2">
            <el-input
              v-model="form.userPsw2"
              type="password"
              style="width:300px;"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="权限设置:" prop="menuIds">
            <el-select v-model="modform.menuIds" multiple placeholder="请选择">
              <el-option
                v-for="item in menusAry"
                :key="item.menuId"
                :label="item.menuName"
                :value="item.menuId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="userInfoHandleClose">取 消</el-button>
          <el-button type="primary" @click="updateUserInfo">确 定</el-button>
        </span>
      </el-dialog>
      <XsjlInfo />
    </div>
  </div>
</template>

<script>
import ShopHeader from "../components/ShopHeader.vue";
import ShopNavigation from "../components/ShopNavigation.vue";
import XsjlInfo from "../components/XsjlInfo.vue";
export default {
  name: "WxAccuntManage",
  components: {
    ShopHeader,
    ShopNavigation,
    XsjlInfo
  },
  data() {
    var validatorPhone = function(rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      query: {
        nickName: "",
        userName: "",
        userCw: "",
        userPhone: "",
        pageNum: 1,
        pageSize: 10
      },
      tableData: [],
      totalNum: 0,
      dialogVisible: false,
      form: {
        userName: "",
        userPhone: "",
        userPsw: "",
        userPsw2: "",
        menuIds: []
      },
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        userPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" }
        ],
        userPsw: [{ required: true, message: "请输入密码", trigger: "blur" }],
        userPsw2: [{ required: true, message: "请输入密码", trigger: "blur" }],
        menuIds: [{ required: true, message: "请设置权限", trigger: "blur" }]
      },
      isMain: 0,
      userInfoDialogVisible: false,
      updateWxUserInfo: {},
      menusAry: [],
      modifyDialogVisible: false,
      modform: {
        khbh: "",
        userName: "",
        userPhone: "",
        // userPsw: "",
        // userPsw2: "",
        menuIds: []
      },
      modifyules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        userPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" }
        ],
        menuIds: [{ required: true, message: "请设置权限", trigger: "blur" }]
      }
    };
  },
  mounted() {
    var userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.isMain = userInfo.isMain;
    this.searchList();
    this.selectAllMenus();
  },
  methods: {
    aligncenter() {
      return "text-align:center;";
    },
    async selectAllMenus() {
      const { data: res } = await this.$http.get("/userMenu/list");
      if (res.code === "K-000000") {
        this.menusAry = res.context;
        this.totalNum = res.context.total;
      }
    },
    async searchList() {
      const { data: res } = await this.$http.post("/user/list", this.query);
      if (res.code === "K-000000") {
        this.tableData = res.context.records;
        this.totalNum = res.context.total;
      }
    },
    handleCurrentChange(newPageNum) {
      this.query.pageNum = newPageNum;
      this.searchList();
    },
    remove(data) {
      this.$confirm("确认删除此子账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.updateUserWx(data);
        })
        .catch(() => {});
    },
    async updateUserWx(data) {
      const { data: res } = await this.$http.get("/user/del/" + data.khbh);
      if (res.code === "K-000000") {
        this.$message.success("删除成功");
        this.searchList();
      } else {
        this.$message.error("删除失败");
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {
        userName: "",
        userPhone: "",
        userPsw: "",
        userPsw2: "",
        menuids: []
      };
    },
    addNewAccount() {
      if (this.form.userPsw2 !== this.form.userPsw) {
        return this.$message.error("两次输入密码不一致");
      }
      this.form.userPhone = this.form.userPhone.trim();
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const { data: res } = await this.$http.post("/user/add", this.form);
        if (res.code === "K-000011") {
          return this.$message.error("已存在相同的手机号!");
        }
        if (res.code === "K-000007") {
          return this.$message.error("存在非法参数!");
        }

        if (res.code !== "K-000000") {
          return this.$message.error("新增子账号失败!");
        } else {
          this.$message.success("新增子账号成功");
          this.searchList();
          this.handleClose();
        }
      });
    },
    userInfoShowDialog(data) {
      this.modifyDialogVisible = true;
      console.log(data);
      this.modform.userName = data.khfzr;
      this.modform.userPhone = data.khsj;
      this.modform.menuIds = data.menusList;
      this.modform.khbh = data.khbh;
    },
    userInfoHandleClose() {
      this.modifyDialogVisible = false;
      this.modform = {
        khbh: "",
        userName: "",
        userPhone: "",
        menuIds: []
      };
    },
    updateUserInfo() {
      this.$refs.modifyform.validate(async valid => {
        if (!valid) return;
        this.modform.userPhone = this.modform.userPhone.trim();
        const { data: res } = await this.$http.post(
          "/user/modify",
          this.modform
        );
        if (res.code === "K-000011") {
          return this.$message.error("已存在相同的手机号!");
        }
        if (res.code === "K-000007") {
          return this.$message.error("存在非法参数!");
        }
        if (res.code !== "K-000000") {
          return this.$message.error("用户信息修改失败!");
        } else {
          this.$message.success("用户信息修改成功");
          this.searchList();
          this.userInfoHandleClose();
        }
      });
    }
  }
};
</script>

<style scoped>
.goodsfengye {
  text-align: center;
  margin-top: 20px;
}
</style>
