import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Jszc from '../views/Jszc.vue'
import Login from '../views/Login.vue'
import Shop from '../views/Shop.vue'
import Wxbind from '../views/WxBind.vue'
import GoodSearch from '../views/GoodSearch.vue'
import ShoppingCar from '../views/ShoppingCar.vue'
import CustomerAddress from '../views/CustomerAddress.vue'
import error500 from '../views/error500.vue'
import OrderConfirm from '../views/OrderConfirm.vue'
import OrderSuccess from '../views/OrderSuccess.vue'
import MyOrder from '../views/MyOrder.vue'
import PasswordUpdate from '../views/PasswordUpdate.vue'
import PasswordUpdate2 from '../views/PasswordUpdate2.vue'
import WxAccuntManage from '../views/WxAccuntManage.vue'
import TouristSearch from '../views/TouristSearch.vue'

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/jszc',
    name: 'Jszc',
    component: Jszc
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/wxbind',
    name: 'Wxbind',
    component: Wxbind
  },
  {
    path: '/goodsearch',
    name: 'GoodSearch',
    component: GoodSearch
  },
  {
    path: '/shoppingCar',
    name: 'ShoppingCar',
    component: ShoppingCar
  },
  {
    path: '/error500',
    name: 'error500',
    component: error500
  },
  {
    path: '/orderConfirm',
    name: 'OrderConfirm',
    component: OrderConfirm
  },
  {
    path: '/orderSuccess',
    name: 'OrderSuccess',
    component: OrderSuccess
  },
  {
    path: '/myOrder',
    name: 'MyOrder',
    component: MyOrder
  },
  {
    path: '/passwordUpdate',
    name: 'PasswordUpdate',
    component: PasswordUpdate
  },
  {
    path: '/passwordUpdate2',
    name: 'PasswordUpdate2',
    component: PasswordUpdate2
  },
  {
    path: '/wxAccuntManage',
    name: 'WxAccuntManage',
    component: WxAccuntManage
  },
  {
    path: '/customerAddress',
    name: 'CustomerAddress',
    component: CustomerAddress
  },
  {
    path: '/touristSearch',
    name: 'TouristSearch',
    component: TouristSearch
  }

  
  
]


const router = new VueRouter({
  mode: 'hash',
  routes:routes
})

router.beforeEach((to,from,next)=>{
  console.log(to,"to")
  console.log(from,"from")
  if(to.name==="PasswordUpdate"){
    var token  =  window.sessionStorage.getItem("token");
    if(from.name!=="Login" || token.length < 10){
      return next({
                name: from.name
               }) 
    }
  }

  // if(to.name==="Login"){
  //     var token  =  window.sessionStorage.getItem("token");
  //     if(token &&  token.length > 10){
  //        return next({
  //         name: "Shop"
  //        }) 
  //     }
  // }
  next()
})

export default router
