<template>
  <div class="footer">
    <p>江苏维创散热器制造有限公司版权所有</p>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scope>
.footer {
  text-align: center;
  color: #666;
  font-size: 16px;
  margin: 0 auto;
  line-height: 15px;
  height: 35px;
}
</style>
