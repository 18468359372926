<template>
  <div class="login_main allLoading">
    <div class="login_header">
      <img data-v-29f959ab="" :src="company1" @click="back" />
      <h1>维创散热器云端互动</h1>
    </div>

    <div class="login_body">
      <div class="gsimg">
        <img :src="company2" />
      </div>
      <div class="login">
        <h3>用户登录</h3>
        <div class="username">
          <span>账号:</span><input type="text" v-model="user.userName" />
        </div>
        <div class="password">
          <span>密码:</span><input type="password" v-model="user.userPsw" />
        </div>
        <a href="#" @click.prevent="login"
          >登<span style="margin-left:1vw;">录</span></a
        >
        <div class="wxdiv" @click="wxLogin">
          <img :src="wximg" /><span style="display:block">微信登录</span>
        </div>
        <div class="youke" @click="touristLogin">
          <img :src="youke" /><span style="display:block">游客模式</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      wximg: require("../assets/img/wx.png"),
      youke: require("../assets/img/youke.png"),
      company1: require("../assets/img/logo_new.png"),
      company2: require("../assets/img/company2.png"),
      user: {
        userName: "",
        userPsw: ""
      },
      unionId: this.$route.query.unionId
    };
  },
  created() {
    if (this.unionId) {
      this.unionIdlogin();
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: "Home"
      });
    },
    wxLogin() {
      window.location.href =
        "https://open.weixin.qq.com/connect/qrconnect?appid=wx35ee275a405dab26&redirect_uri=http%3A%2F%2Fclient.jswcsrq.com%2Fshop%2Fuser%2Fwxlogin&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
    },
    async login() {
      if (!this.user.userName) {
        return this.$message.error("请输入账号");
      }
      if (!this.user.userPsw) {
        return this.$message.error("请输入密码");
      }
      const { data: res } = await this.$http.post("/user/login", this.user);
      console.log(res);
      if (res.code === "K-000002") {
        return this.$message.error("你输入的账号或密码不存在");
      }
      if (res.code === "K-000001") {
        return this.$message.error("登录失败,请联系管理员");
      }
      if (res.code === "K-000000") {
        let userInfo = JSON.parse(res.context.userInfo);
        console.log(userInfo);
        //判断是否绑定微信
        if (userInfo.unionId) {
          window.sessionStorage.setItem("token", res.context.token);
          window.sessionStorage.setItem("userInfo", res.context.userInfo);
          this.$router.push({
            name: "Shop"
          });
        } else {
          this.$confirm(
            "您的账号尚未绑定微信账号!!,请确定是否前往绑定?",
            "提示",
            {
              confirmButtonText: "前往绑定",
              cancelButtonText: "暂不绑定",
              type: "warning"
            }
          )
            .then(() => {
              this.wxLogin();
            })
            .catch(() => {
              window.sessionStorage.setItem("token", res.context.token);
              window.sessionStorage.setItem("userInfo", res.context.userInfo);
              if (res.context.firstLogin) {
                this.$router.push({
                  name: "Shop"
                });
              } else {
                this.$router.push({
                  name: "PasswordUpdate"
                });
              }
              console.log("取消");
            });
        }
      }
    },
    async unionIdlogin() {
      // let loadingInstance = this.$loading({
      //   fullscreen: true,
      //   text: "数据加载中", // 显示文本
      //   // spinner: "el-icon-loading", // 显示的 icon
      //   background: "rgba(0, 0, 0, 0.7)" // loading 遮罩层颜色
      // });
      const { data: res } = await this.$http.post("/user/unionIdlogin", {
        unionId: this.unionId
      });
      if (res.code === "K-000002") {
        this.$alert("您的账号尚未绑定微信账号!!,请点击确定前往绑定。", "提示", {
          confirmButtonText: "确定",
          callback: action => {
            this.wxLogin();
          }
        });
      }
      if (res.code === "K-000000") {
        window.sessionStorage.setItem("token", res.context.token);
        window.sessionStorage.setItem("userInfo", res.context.userInfo);
        // this.$nextTick(() => {
        //   // 以服务的方式调用的 Loading 需要异步关闭
        //   loadingInstance.close();
        // });
        this.$router.push({
          name: "Shop"
        });
      }
      if (res.code === "K-000001") {
        this.$router.push({
          name: "error500"
        });
      }
    },
    async touristLogin() {
      this.$router.push({
        name: "TouristSearch"
      });
    }
  }
};
</script>

<style scope>
.login_main {
  background-image: url(../assets/img/bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.login_header {
  height: 18.2vh;
  width: 65vw;
  margin: 0 auto;
  cursor: pointer;
}

.login_header > img {
  float: left;
  width: 14.4vw;
  height: 10vh;
  margin-top: 4.6vh;
}

.login_header > h1 {
  float: right;
  display: inline;
  color: white;
  margin-top: 5.5vh;
  margin-right: 11.7vw;
  font-size: 3vw;
}

.login_body {
  width: 65vw;
  height: 42.4vh;
  margin: 0 auto;
  margin-top: 2vh;
}

.gsimg {
  float: left;
  width: 40.5vw;
  height: 42.4vh;
}

.gsimg > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.login {
  float: left;
  background-color: rgb(1, 3, 14);
  width: 21.7vw;
  height: 42.4vh;
  margin-left: 2.7vw;
  opacity: 0.5;
  border-radius: 10px;
}

.login > h3 {
  text-align: center;
  color: white;
  margin-top: 3vh;
  font-size: 2vw;
}

.username,
.password {
  width: 100%;
  margin: 0 auto;
  margin-top: 2.4vh;
  margin-left: 1.4vw;
  color: white;
  font-size: 1.2vw;
}

.username > input {
  width: 16vw;
  height: 3.3vh;
  font-size: 1vw;
}

.password > input {
  width: 16vw;
  height: 3.3vh;
  font-size: 1vw;
}

.login > a {
  display: block;
  border: 1px solid #00a4ff;
  width: 19vw;
  height: 3.5vh;
  text-align: center;
  line-height: 3.2vh;
  color: white;
  background-color: #00a4ff;
  margin: 1.8vw auto;
  font-size: 1.5vw;
  font-weight: 700;
  border-radius: 5px;
}

.wxdiv {
  margin-left: 6vw;
  float: left;
  color: white;
  height: 1.9vh;
  line-height: 1.9vh;
  cursor: pointer;
  text-align: center;
}

.youke {
  float: right;
  margin-right: 4vw;
  text-align: center;
  color: white;
  height: 1.9vh;
  line-height: 1.9vh;
  cursor: pointer;
}

.youke > img {
  width: 21px;
  height: 21px;
}

.wxiframe {
  width: 300px;
  height: 400px;
  margin: 10px 50px;
}
</style>
