<template>
  <div class="xiaoshoujinliinforight">
    <img src="../assets/img/kefu.jpg" v-if="!khywurl" />
    <img :src="khywurl" v-if="khywurl" />
    <div class="xiaoshoujinliinforight_mc">您的客户经理</div>
    <div class="xiaoshoujinliinforight_mc">{{ khywy }}</div>
    <div class="xiaoshoujinliinforight_tel">
      {{ khywysj }}
    </div>
  </div>
</template>

<script>
export default {
  name: "XsjlInfo",
  data() {
    return {
      khywy: "",
      khywysj: "",
      khywurl: ""
    };
  },
  mounted() {
    var userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    this.khywy = userInfo.khywy;
    this.khywysj = userInfo.khywysj;
    if (!this.khywur) {
      this.khywurl = "http://jrsoft.jswcsrq.com:81" + userInfo.khywurl;
    }
  }
};
</script>

<style scoped>
.xiaoshoujinliinforight {
  position: fixed;
  z-index: 2008;
  right: 0%;
  width: 8.1vw;
  height: 32.6vh;
  border: 1px solid #00a4ff;
  background: whitesmoke;
  top: 20%;
  border-radius: 10px;
}

.xiaoshoujinliinforight > img {
  width: 8.1vw;
  height: 23.6vh;
  border-radius: 7px;
}

.xiaoshoujinliinforight_mc {
  text-align: center;
  font-size: 1vw;
  margin-top: 0.2vh;
}

.xiaoshoujinliinforight_tel {
  text-align: center;
  line-height: 24px;
  font-size: 1.2vw;
  margin-top: 0.2vh;
  /* -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  margin-left: 27px; */
}
</style>
