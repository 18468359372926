<template>
  <div class="hello">
    <div class="floor4">
      <div class="floor4_box1">
        <img
          src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/f/3/f/6/thumb_5a2e7165af347.png"
        />
        <h4>维修视频</h4>
      </div>
      <div class="floor4_box2">
        <!-- <img
          src="http://www.qdwss.com/tpl/static/attachment/icon/canyin/canyin_red/21.png"
        /> -->
        <h4>柴油发电机组水温过高对冷却水箱造成的影响</h4>
      </div>
      <div class="floor4_box3">
        <!-- <img
          src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/5/1/6/6/thumb_5c64b79481083.png"
        /> -->
        <h4>品质维创--皮带传动说明书</h4>
      </div>
    </div>

    <div class="floor5">
      <div class="floor5_box1">
        <!-- <img
          src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/1/0/d/8/thumb_5c64c2ff5e0b8.png"
        /> -->
        <h4>夏季散热器的维护</h4>
      </div>
      <div class="floor5_box2">
        <!-- <img
          src="http://www.qdwss.com/tpl/static/attachment/icon/canyin/canyin_red/10.png"
        /> -->
        <h4>技术论坛</h4>
      </div>
    </div>

    <div class="floor6">
      <ul>
        <li>
          <a href="#">
            <img
              src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/4/1/6/3/thumb_568f28fc8e66a.png"
            />
            <h4>工程师在线</h4>
          </a>
        </li>
        <li>
          <a href="#">
            <img
              src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/c/4/1/5/thumb_568e2421366ea.png"
            />
            <h4>服务探讨</h4></a
          >
        </li>
        <li>
          <a href="#">
            <img
              src="http://www.qdwss.com/uploads/o/oxjlsc1412663362/c/4/1/5/thumb_568e2421366ea.png"
            />
            <h4>安装图样</h4></a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBody2"
};
</script>

<style scope>
.floor4 {
  margin-top: 10px;
  height: 300px;
}

.floor4_box1 {
  float: left;
  width: 500px;
  height: 300px;
  background-color: #00ccff;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
}

.floor4_box1 > img {
  width: 240px;
  height: 220px;
  padding-top: 25px;
}

.floor4_box1 > h4 {
  font-size: 30px;
  color: white;
}

.floor4_box2 {
  float: right;
  width: 690px;
  height: 140px;
  background-color: #2b87ee;
  border-radius: 10px;
  padding-top: 15px;
  cursor: pointer;
  text-align: center;
}

.floor4_box2 > img {
  width: 150px;
  height: 100px;
}

.floor4_box2 > h4 {
  font-size: 25px;
  color: white;
  line-height: 130px;
}

.floor4_box3 {
  float: right;
  margin-top: 10px;
  width: 690px;
  height: 140px;
  background-color: #ab09b4;
  border-radius: 15px;
  cursor: pointer;
  text-align: center;
}

.floor4_box3 > img {
  width: 150px;
  height: 100px;
}

.floor4_box3 > h4 {
  font-size: 25px;
  color: white;
  line-height: 130px;
}

.floor5 {
  margin-top: 10px;
  height: 150px;
  cursor: pointer;
}

.floor5_box1 {
  float: left;
  width: 595px;
  height: 150px;
  background-color: #00ccff;
  border-radius: 15px;
  text-align: center;
}

.floor5_box1 > img {
  width: 80%;
  height: 100%;
}

.floor5_box1 > h4 {
  font-size: 25px;
  color: white;
  line-height: 130px;
}

.floor5_box2 {
  float: right;
  width: 595px;
  height: 150px;
  background-color: #2b87ee;
  border-radius: 15px;
  text-align: center;
}

.floor5_box2 > img {
  width: 150px;
  height: 100px;
}

.floor5_box2 > h4 {
  font-size: 25px;
  color: white;
  line-height: 130px;
}

.floor6 {
  margin-top: 10px;
  height: 150px;
}

.floor6 ul {
  float: left;
  width: 1200px;
  margin-left: 5px;
}

.floor6 ul li {
  position: relative;
  float: left;
  width: 390px;
  height: 180px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 15px;
}

.floor6 ul li a {
  text-align: center;
  color: aliceblue;
}

.floor6 ul li a > img {
  width: 200px;
  height: 150px;
  margin-left: 100px;
}

.floor6 ul li:nth-child(1) {
  background-color: #a903ad;
}

.floor6 ul li:nth-child(2) {
  background-color: #00ccff;
}
.floor6 ul li:nth-child(3) {
  background-color: #11ac13;
}
</style>
