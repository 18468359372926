<template>
  <div>
    <el-table
      :data="tableData"
      style="width: 1400px;margin-top:10px;"
      :cell-style="aligncenter"
      :span-method="arraySpanMethod"
      stripe
    >
      <!-- <el-table-column label="序号" type="index" header-align="center">
      </el-table-column> -->
      <el-table-column label="订购日期" header-align="center" width="100">
        <template slot-scope="scope">
          {{ scope.row.createTime.substr(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column
        label="订购人"
        header-align="center"
        prop="customerName"
        width="100"
      >
      </el-table-column>
      <el-table-column label="订购商品" header-align="center">
        <el-table-column
          label="型号"
          header-align="center"
          prop="spGg"
          width="350"
        >
        </el-table-column>
        <el-table-column
          label="订购数量"
          header-align="center"
          prop="spNum"
          width="100"
        >
        </el-table-column>
        <el-table-column label="金额" header-align="center" width="100">
          <template slot-scope="scope">
            <span style="color: #cb4255; font-weight: 700;">
              ￥{{ scope.row.spNum * scope.row.spMoney }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="备注" header-align="center" prop="goodsRemarks">
        </el-table-column>
      </el-table-column>
      <el-table-column label="状态" header-align="center" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.orderStatus === 1">待受理</span>
          <span v-if="scope.row.orderStatus === 2">待生产</span>
          <span v-if="scope.row.orderStatus === 3">待发货</span>
          <span v-if="scope.row.orderStatus === 4">已发货</span>
          <span v-if="scope.row.orderStatus === 5">已作废</span>
        </template>
      </el-table-column>
      <el-table-column label="发货日期" header-align="center" width="100">
        <template slot-scope="scope" v-if="scope.row.deliverGoodsDate">
          {{ scope.row.deliverGoodsDate.substr(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column
        label="发货数量"
        header-align="center"
        prop="deliverGoodsNum"
        width="100"
      >
      </el-table-column>

      <el-table-column label="总金额" header-align="center">
        <template slot-scope="scope">
          <span style="color: #cb4255; font-weight: 700;"
            >￥{{ scope.row.orderTotalMoney }}</span
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="操作" header-align="center">
        <template slot-scope="scope">
          <div>
            <el-button type="danger" round size="mini">去付款</el-button>
          </div>
          <div class="myorderdetail" @click="showOrderDetail(scope.row)">
            查看详情
          </div>
          <div class="myorderdetail">
            取消订单
          </div>
        </template>
      </el-table-column> -->
    </el-table>

    <el-dialog title="订单详情" :visible.sync="orderDetailVisible" width="60%">
      <el-row>
        <el-col :span="3"><div class="orderDataTitle">订单编号:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            {{ orderDetail.orderCode }}
          </div></el-col
        >
        <el-col :span="3"><div class="orderDataTitle">下单时间:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            {{ orderDetail.createTime }}
          </div></el-col
        >
      </el-row>

      <el-row>
        <el-col :span="3"><div class="orderDataTitle">订单状态:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            待发货
          </div></el-col
        >
        <el-col :span="3"><div class="orderDataTitle">收货地址:</div></el-col>
        <el-col :span="12"
          ><div class="orderDataContent">
            {{ orderDetail.deliveryName }} {{ orderDetail.deliveryNumber }}
            {{ orderDetail.deliveryAddress }}
          </div></el-col
        >
      </el-row>

      <el-row>
        <el-col :span="3"><div class="orderDataTitle">支付方式:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            {{ orderDetail.payMethod === 2 ? "线下支付" : "在线支付" }}
          </div></el-col
        >
        <el-col :span="3"><div class="orderDataTitle">配送方式:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            {{ orderDetail.deliveryMethod === 1 ? "快递" : "自提" }}
          </div></el-col
        >
      </el-row>

      <el-row>
        <el-col :span="3"><div class="orderDataTitle">备注:</div></el-col>
        <el-col :span="6"
          ><div class="orderDataContent">
            {{ orderDetail.remarks }}
          </div></el-col
        >
      </el-row>

      <el-table
        :data="orderDetail.customerOrderGoodsVOList"
        style="width: 100%"
      >
        <el-table-column label="商品名称" width="450">
          <template slot-scope="scope">
            <div class="shoppingcargoodsimg">
              <img
                src="http://www.jswcsrq.com/static/upload/image/20210831/1630405325238334.jpg"
              />
              <div class="shoppingcargoodsname">
                {{ scope.row.spMc }}{{ scope.row.spGg }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="spNum" label="数量"> </el-table-column>
        <el-table-column label="单价">
          <template slot-scope="scope">
            <div class="shoppingcargoodsprice">￥{{ scope.row.spMoney }}</div>
          </template>
        </el-table-column>
        <el-table-column label="小计">
          <template slot-scope="scope">
            <div class="shoppingcargoodsprice">
              ￥{{ scope.row.spMoney * scope.row.spNum }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="confirmBody_footer">
        <div>
          商品总额:<span>￥{{ orderDetail.orderTotalMoney }}</span>
        </div>
        <div>
          配送费用:<span>￥{{ orderDetail.deliveryMoney }}</span>
        </div>
        <div>
          订单金额:<span>￥{{ orderDetail.orderMoney }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderData",
  data() {
    return {
      orderDetailVisible: false,
      orderDetail: {}
    };
  },
  props: ["tableData"],
  methods: {
    aligncenter() {
      return "text-align:center;";
    },
    showOrderDetail(data) {
      this.orderDetailVisible = true;
      this.orderDetail = data;
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex === 9) {
      //   if (row.lasted === "1") {
      //     return {
      //       rowspan: row.customerOrderGoodsVOList.length,
      //       colspan: 1
      //     };
      //   } else {
      //     return {
      //       rowspan: 0,
      //       colspan: 0
      //     };
      //   }
      // }
    }
  }
};
</script>

<style scoped>
.ordergoodsinfo {
  height: 100px;
  width: 450px;
  text-align: left;
}

.ordersgoodsimg > img {
  width: 80px;
  height: 80px;
  margin-right: 5px;
}

.orderprice {
  font-weight: 700;
  color: #cb4255;
  font-size: 16px;
}
.myorderdetail {
  cursor: pointer;
}

.orderDataTitle {
  text-align: center;
}

.orderDataContent {
  text-align: left;
}

.el-row {
  margin-bottom: 10px;
}

.shoppingcargoodsimg > img {
  float: left;
  width: 150px;
  height: 100px;
  overflow: auto;
}
.shoppingcargoodsname {
  float: left;
  line-height: 100px;
  font-size: 16px;
}
.shoppingcargoodsprice {
  color: #cb4255;
  font-weight: 700;
  font-size: 16px;
}

.confirmBody_footer {
  height: 120px;
}

.confirmBody_footer > div {
  margin: 15px 186px;
  height: 30px;
  margin-left: 76%;
  margin-top: 10px;
  width: 200px;
}

.confirmBody_footer > div > span {
  color: #cb4255;
  font-weight: 700;
  font-size: 16px;
}

/* .el-table .warning-row {
  background-color: #f5f7fa;
}

.el-table .success-row {
  background-color: white;
} */
</style>
